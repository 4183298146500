import React from 'react';
import { isLoaded } from 'react-redux-firebase';

import Loading from 'common/components/Loading';
import ReimbursableListItem from '../containers/ReimbursableListItem';

export default ({ reimbursables }) => {
    if (!isLoaded(reimbursables)) {
        return <Loading />;
    }

    return (
        <table className='table table-striped table-responsive-sm'>
            <thead>
                <tr>
                    <th scope='col'>Date</th>
                    <th scope='col'>Description</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Repaid</th>
                    <th scope='col'>Repayer</th>
                    <th scope='col'>Expected Date</th>
                    <th scope='col'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {reimbursables != null ? Object.keys(reimbursables).map((k) => <ReimbursableListItem reimbursable={reimbursables[k]} key={k} id={k} />) : null}
            </tbody>
        </table>
    );
};