import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { isLoaded } from 'react-redux-firebase';

import Chart from 'common/components/Chart';
import Loading from 'common/components/Loading';

class MonthCategories extends Component {

    formatLabel = (item, data) => {
        let label = data.datasets[item.datasetIndex].label || '';

        return `${label}: $${item.yLabel.toFixed(2)}`;
    }

    render() {
        const {
            aggregates
        } = this.props;

        if (!isLoaded(aggregates)) {
            return <Loading />;
        }

        return (
            <Chart component={Bar}
                legendClassName='mb-4'
                data={{
                    labels: aggregates.labels,
                    datasets: aggregates.datasets
                }} options={{
                    legend: {
                        display: false
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: this.formatLabel
                        }
                    }
                }}/>
        );
    }
}

export default MonthCategories;