import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Loading from 'common/components/Loading';

class Home extends Component {
    render() {
        if (this.props.authExists) {
            return <Redirect to='/dashboard' />;
        }
        else if (!this.props.isLoaded) {
            return <Loading />;
        }

        return (
            <div className='landing-page w-100 h-100 d-flex justify-content-center align-items-center'>
                <Helmet>
                    <title>Budget</title>
                </Helmet>

                <div className='w-100 d-flex row mt-lg-5'>
                    <div className="col-md-4 d-none d-md-flex align-items-center justify-content-center">
                        <span className='logo'>$</span>
                    </div>
                    <div className="col-12 col-md-8">
                        <h1 className='display-5 d-lg-none'>Conscious spending with concise analysis</h1>
                        <h1 className='display-4 d-none d-lg-inline'>Conscious spending with concise analysis</h1>
                        <p className='mt-2'>Create a daily budget, track your spending, and stay on track.</p>
                        <p className='lead'>
                            <Link className='btn btn-outline-primary btn-lg' to='/register' role='button'>Register</Link>
                            &nbsp;|&nbsp;
                            <Link className='btn btn-outline-dark btn-lg' to='/login' role='button'>Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;