import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Login extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: ''
        };
    }

    login = (e) => {
        e.preventDefault();

        if (!this.state.email || !this.state.password) {
            return;
        }

        this.props.firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(response => {
                this.setState({
                    error: null
                });

                return this.props.setUser({
                    uid: response.user.uid,
                    email: response.user.email,
                    displayName: response.user.displayName
                });
            }, (error) => {
                this.setState({
                    error: error && error.message ? error.message : 'Unknown failure'
                });
            });
    }

    onValueChanged = (e) => {
        let update = {};
        update[e.target.id] = e.target.value || '';

        this.setState(update);
    }

    render() {
        if (this.props.authExists) {
            let location = window.location.search.split('=')[1] || '/dashboard';
            return <Redirect to={location} />;
        }

        return (
            <div>
                <Helmet>
                    <title>Budget - Login</title>
                </Helmet>

                <article className='row mb-4 justify-content-sm-center'>
                    <div className='col-12 col-md-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h2>Login</h2>
                            </div>

                            <div className='card-body mt-4'>
                                <form onSubmit={this.login}>
                                    <div className='form-group form-row'>
                                        <label htmlFor='email' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Email</label>
                                        <input type='email' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='email' value={this.state.email || ''} onChange={this.onValueChanged} autoFocus required />
                                    </div>
                                    <div className='form-group form-row'>
                                        <label htmlFor='password' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Password</label>
                                        <input type='password' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='password' value={this.state.password || ''} onChange={this.onValueChanged} required />
                                        <small id='passwordHelp' className='form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2'>
                                            <Link to='/reset'>Forgot your password?</Link>
                                        </small>
                                    </div>

                                    {
                                        this.state.error ? (
                                            <div className='form-group row'>
                                                <span className='col-12 text-danger'>{this.state.error}</span>
                                            </div>
                                        ) : null
                                    }

                                    <hr />

                                    <div>
                                        <button type='submit' className='btn btn-primary' onClick={this.login}>Login</button> or <Link to='/register'>register</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default withFirebase(Login);