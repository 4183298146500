import React, { Component } from 'react';
import { withFirestore, isLoaded } from 'react-redux-firebase';
import classNames from 'classnames';

import FormActions from 'common/components/FormActions';
import Loading from 'common/components/Loading';

class EditSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props.budget,
            isBusy: false
        };
    }

    save = async (e) => {
        const { firebase } = this.props;
        const { user } = this.props.auth;

        this.setState({
            isBusy: true
        });

        let b = this.state;

        e.preventDefault();

        await this.props.firestore.set({ collection: 'budget', doc: user.uid }, {
            monthly: b.monthly,
            income: b.income,
            fixed: b.fixed
        });

        this.setState({
            saveMessage: 'Coolio. Thanks for the update moneybags! Did you know you can add other currencies and track IOUs with us? Wow.',
            isBusy: false
        });
    }

    cancel = (e) => {
        e.preventDefault();

        this.setState({
            ...this.props.budget
        });
    }

    onValueChanged = (e) => {
        let update = {
            ...this.state
        };

        let prop = e.target.dataset.property;

        update[prop] = +e.target.value || 0;

        if (prop === 'income' || prop === 'fixed') {
            let income = +update.income || 0;
            let fixed = +update.fixed || 0;

            update.monthly = Math.max(income - fixed, 0);
        }

        this.setState({
            ...update
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...props.budget,
            ...state
        };
    }

    render() {
        const budget = this.state;
        const { user } = this.props.auth;

        if (!isLoaded(this.props.budget)) {
            return <Loading />;
        }

        return (
            <form onSubmit={this.save}>
                <div className='form-group form-row'>
                    <div className='col-12'>
                        <p>We've taken a stab at a monthly budget for you, but nothing is ever so simple, so please use the below steps to calculate your monthly budget.</p>
                        <p>
                            <ol>
                                <li>Determine your minimum monthy net income (takehome pay after taxes, etc.) and enter it below in the <b>Monthly Income</b> field.</li>
                                <li>Calculate all your fixed expenses (aka your needs like rent, utilities, savings, whiskey and the like) and enter it into <b>Fixed Expenses</b>.</li>
                                <li>Let us take care of the hard maths and pre-populate your <b>Monthly Budget</b>.</li>
                                <li>If so desired, update your monthly disposable budget as needed after it has been calculated for you if you need to account for other deductions.</li>
                                <li>Once done, hit <b>Save</b> and explore the rest of the app!</li>
                            </ol>
                        </p>
                        <p>Note that changing your budget at any time may affect your views of historical data when using the "time travel" feature.</p>
                    </div>
                </div>

                <hr />
                <div className='form-group form-row'>
                    <label htmlFor='income' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Monthly Income</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <div className='input-group input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>$</span>
                            </div>
                            <input type='number' className='form-control' id='income' data-property='income' step='1' min='0' value={budget.income || ''} onChange={this.onValueChanged} required />
                        </div>
                    </div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='fixed' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Fixed Expenses</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <div className='input-group input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>$</span>
                            </div>
                            <input type='number' className='form-control' id='fixed' data-property='fixed' step='1' min='0' value={budget.fixed || ''} onChange={this.onValueChanged} required />
                        </div>
                    </div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='monthly' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Monthly Budget</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <div className='input-group input-group'>
                            <div className='input-group-prepend'>
                                <span className={classNames('input-group-text', { 'border-danger': budget.monthly <= 0, 'text-danger': budget.monthly <= 0 })}>$</span>
                            </div>
                            <input type='number' className={classNames('form-control', { 'border-danger': budget.monthly <= 0, 'text-danger': budget.monthly <= 0 })} id='monthly' data-property='monthly' step='1' value={budget.monthly || ''} onChange={this.onValueChanged} required />
                        </div>
                    </div>
                </div>

                { this.state.saveMessage ? (
                    <div className='form-group row'>
                        <span className='col-12 text-success'>{this.state.saveMessage}</span>
                    </div>
                ) : null }

                <hr />

                <FormActions save={this.save} cancel={this.cancel} id={user.uid} isBusy={this.state.isBusy} />
            </form>
        );
    }
}

export default withFirestore(EditSettings);