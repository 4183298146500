import React from 'react';
import classNames from 'classnames';

export default ({ title, children, size }) => {
    let className = classNames('mt-4', 'col-12', {
        'col-sm-6': !size,
    });

    return (
        <div className={ className }>
            <div className='row'>
                <div className='col-12'>
                    <h3 className='font-weight-normal'>{title}</h3>
                </div>
            </div>

            {children}

        </div>
    );
};