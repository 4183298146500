import moment from 'moment';

import * as t from './actionTypes';

const initialState = {
    selectedReimbursable: {
        date: '',
        expectedDate: '',
        description: '',
        amount: '',
        repaid: false,
        repayer: ''
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.NEW_REIMBURSABLE:
            return {
                ...state,
                selectedReimbursable: {
                    ...initialState.selectedReimbursable,
                    ...action.payload
                }
            };
        case t.EDIT_REIMBURSABLE:
            return {
                ...state,
                selectedReimbursable: {
                    ...state.selectedReimbursable,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};