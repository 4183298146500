import React, { Component } from 'react';
import classNames from 'classnames';

class Chart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            legend: null
        };
    }

    generateLegend = () => {
        if  (!this.chart) {
            return null;
        }

        return this.chart.chartInstance.generateLegend();
    }

    componentDidMount() {
        const legend = this.generateLegend();
        this.setState({
            legend
        });
    }

    createLegend = (chart) => {
        if (!chart || !chart.data || !chart.data.datasets || !chart.data.datasets.length) {
            return;
        }

        let className = classNames('d-none', 'd-md-flex', 'justify-content-center', 'align-items-center', this.props.legendClassName)

        let text = [];

        text.push(`<div class="${className}">`);

        chart.data.datasets.forEach(d => {
            text.push([
                '<div style="margin: 0 1rem; border-bottom: 2px ', d.borderDash ? 'dashed' : 'solid', ' ', d.borderColor, '">',
                    d.label,
                '</div>',
            ].join(''));
        });

        text.push('</div>');

        return text.join('');
    }

    createMarkup = () => {
        return { __html: this.state.legend };
    }

    render() {
        const {
            component,
            options,
            ...chartProps
        } = this.props;

        const ChartComponent = component;
        const chartOptions = {
            ...options,
            legendCallback: this.createLegend
        };

        return (
            <div>
                <div dangerouslySetInnerHTML={this.createMarkup()} />
                <ChartComponent ref={element => this.chart = element} options={chartOptions} {...chartProps} />
            </div>
        );
    }
}

export default Chart;