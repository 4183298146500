import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { withAuth } from 'modules/auth';
import withToday from 'common/containers/withToday';
import { getThisMonthsAggregates } from 'data/selectors';

import { default as Component } from '../components/MonthCategories';

export default compose(
    withAuth,
    withToday,
    firestoreConnect(({ auth: { user }, year, startOfMonth }) => {
        return [
            {
                collection: 'monthlyAggregates',
                where: [
                    ['ownerId', '==', user.uid],
                    ['month', '==', startOfMonth]
                ],
                limit: 1,
                storeAs: `monthlyAggregates/${startOfMonth.getTime()}`,
            },
            {
                collection: 'yearlyAggregates',
                where: [
                    ['ownerId', '==', user.uid],
                    ['year', '>=', year - 1],
                    ['year', '<=', year]
                ],
                orderBy: ['year'],
                limit: 2,
                storeAs: `yearlyAggregates/${year}`
            }
        ];
    }),
    connect((state) => {
        return {
            aggregates: getThisMonthsAggregates(state)
        };
    })
)(Component);