import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';

import { withAuth } from 'modules/auth';

import { default as Component } from '../components/Currency';

const getCurrencies = (state) => {
    if (!state || !state.firestore || !state.firestore.ordered ||
        !isLoaded(state.firestore.ordered.currencies)) {
        return { isLoaded: false };
    }

    return state.firestore.ordered.currencies;
};

function mapStateToProps(state) {
    return {
        currencies: getCurrencies(state)
    };
}

const Container = compose(
    withAuth,
    firestoreConnect(({ auth : { user } }) => {
        return [
            {
                collection: 'currencies',
                where: [
                    ['ownerId', '==', user.uid]
                ],
                orderBy: ['abbreviation']
            }
        ];
    }),
    connect(
        mapStateToProps
    )
)(Component);

export default Container;