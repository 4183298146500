import moment from 'moment';

import * as t from './actionTypes';

const defaultDate = moment();
const initialState = {
    today: defaultDate,
    startOfMonth: moment(defaultDate).startOf('month').toDate(),
    endOfMonth: moment(defaultDate).endOf('month').toDate(),
    year: moment(defaultDate).year()
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.EDIT_TODAY:
            return {
                ...state,
                today: action.payload,
                startOfMonth: moment(action.payload).startOf('month').toDate(),
                endOfMonth: moment(action.payload).endOf('month').toDate(),
                year: moment(action.payload).year()
            };
        default:
            return state;
    }
};