import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCog,
    faEdit,
    faLock,
    faMoneyBillAlt,
    faTrashAlt,
    faUser
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faCog,
    faEdit,
    faLock,
    faMoneyBillAlt,
    faTrashAlt,
    faUser
);