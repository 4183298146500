import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import { getProgress, getTodaysDetails, getMonthsDetails, getDays, getPointColors } from 'data/selectors';
import withToday from 'common/containers/withToday';

import { withAuth } from 'modules/auth';

import { default as Component } from '../components/DashboardLayout';

export default compose(
    withAuth,
    withRouter,
    withToday,
    firestoreConnect(({ auth: { user }, startOfMonth, endOfMonth }) => {
        return [
            {
                collection: 'transactions',
                where: [
                    ['ownerId', '==', user.uid],
                    ['date', '>=', startOfMonth],
                    ['date', '<=', endOfMonth],
                ],
                orderBy: ['date'],
                storeAs: `transactions/${startOfMonth.getTime()}`
            },
            {
                collection: 'budget',
                doc: user.uid,
                storeAs: 'budget'
            },
            {
                collection: 'bigExpenses',
                where: [
                    ['ownerId', '==', user.uid],
                    ['startDate', '>=', startOfMonth],
                    ['startDate', '<=', endOfMonth]
                ],
                orderBy: ['startDate'],
                storeAs: `bigExpenses/${startOfMonth.getTime()}`
            }
        ];
    }),
    connect((state) => {
        return {
            days: getDays(state),
            todaysDetail: getTodaysDetails(state),
            monthDetail: getMonthsDetails(state),
            progress: getProgress(state),
            pointColors: getPointColors(state)
        };
    })
)(Component);