import React, { Component } from 'react';
import { withFirestore } from 'react-redux-firebase';

import GridActions from 'common/components/GridActions';

class CurrencyListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    edit = () => {
        this.props.editCurrency({
            id: this.props.id,
            ...this.props.currency
        });
    }

    remove = async () => {
        if (!confirm('Delete this item?')) {
            return;
        }

        this.setState({
            isBusy: true
        });

        let { id } = this.props;
        await this.props.firestore.delete({ collection: 'currencies', doc: id });
    }

    render() {
        let { currency } = this.props;

        if (!currency || !currency.abbreviation) {
            return null;
        }

        return (
            <tr>
                <th scope='row'>{currency.abbreviation}</th>
                <td>{currency.conversion}</td>
                {currency.abbreviation !== 'USD' ? (
                    <GridActions isBusy={this.state.isBusy} onEdit={this.edit} onDelete={this.remove} />
                ) : <td /> }
            </tr>
        );
    }
}

export default withFirestore(CurrencyListItem);