import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withFirebase } from 'react-redux-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from './Link';

import routes from '../routes';

class MainLayout extends Component {
    logout = (e) => {
        this.props.firebase.auth().signOut().then(() =>
            this.props.setUser(null)
        , (e) => console.error(e));
    }

    renderUserMenu = (auth) => {
        // if current user exists and user id exists than make user navigation
        if (auth && auth.user && auth.user.uid) {
            const { user } = auth;

            return [
                <li className='nav-item dropdown d-none d-lg-flex' key={2}>
                    <a href='#' className='nav-link dropdown-toggle' data-toggle='dropdown' role='button'
                        aria-haspopup='true' aria-expanded='false'>
                        { false ? <img className='user-avatar' src={user.photoURL} /> : null }
                        <span>
                            {user.displayName || user.email}
                        </span>
                        <span className='caret'></span>
                    </a>
                    <div className='dropdown-menu dropdown-menu-right'>
                        <RouterLink className='dropdown-item' to='/currencies'><FontAwesomeIcon icon={['fas', 'money-bill-alt']} /> Currencies</RouterLink>
                        <RouterLink className='dropdown-item' to='/settings'><FontAwesomeIcon icon={['fas', 'cog']} /> Settings</RouterLink>
                        <div className='dropdown-divider'></div>
                        <RouterLink className='dropdown-item' to='/profile'><FontAwesomeIcon icon={['fas', 'user']} /> Profile</RouterLink>
                        <a href='javascript:void(0)' className='dropdown-item' onClick={this.logout}><FontAwesomeIcon icon={['fas', 'lock']} /> Logout</a>
                    </div>
                </li>,
                <li className='nav-item d-block d-lg-none' key={3}>
                    <div className='dropdown-divider'></div>
                    <RouterLink className='nav-link' to='/currencies'><FontAwesomeIcon icon={['fas', 'money-bill-alt']} /> Currencies</RouterLink>
                </li>,
                <li className='nav-item d-block d-lg-none' key={4}>
                    <RouterLink className='nav-link' to='/settings'><FontAwesomeIcon icon={['fas', 'cog']} /> Settings</RouterLink>
                </li>,
                <li className='nav-item d-block d-lg-none' key={5}>
                    <div className='dropdown-divider'></div>
                    <RouterLink className='nav-link' to='/profile'><FontAwesomeIcon icon={['fas', 'user']} /> Profile</RouterLink>
                </li>,
                <li className='nav-item d-block d-lg-none' key={6}>
                    <a href='javascript:void(0)' className='nav-link' onClick={this.logout}><FontAwesomeIcon icon={['fas', 'lock']} /> Logout</a>
                </li>
            ];
        }
        else {
            return [
                <li className='nav-item' key='login'>
                    <RouterLink className='nav-link' to='/login'>Login</RouterLink>
                </li>,
                <li className='nav-item' key='register'>
                    <RouterLink className='nav-link' to='/register'>Register</RouterLink>
                </li>
            ];
        }

    }

    render() {
        const { auth } = this.props;

        return (
            <div>
                <nav className='navbar navbar-expand-lg navbar-light bg-light fixed-top'>
                    <RouterLink className='navbar-brand mb-0 h1' to={ auth && auth.user && auth.user.uid ? '/dashboard' : '/' }>Budget</RouterLink>
                    <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#mainNav' aria-controls='mainNav' aria-expanded='false' aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'></span>
                    </button>

                    <div className='collapse navbar-collapse' id='mainNav'>
                        { auth && auth.user && auth.user.uid ? (
                            <ul className='navbar-nav mr-auto'>
                                <Link to='/dashboard' target='#mainNav'>Dashboard</Link>
                                <Link to='/forecast' target='#mainNav'>Forecast</Link>
                                <Link to='/transactions' target='#mainNav'>Transactions</Link>
                                <Link to='/bigExpenses' target='#mainNav'>Big Expenses</Link>
                                <Link to='/reimbursables' target='#mainNav'>Reimbursables</Link>
                            </ul>
                        ) : <ul className='navbar-nav mr-auto'></ul> }

                        <ul className='navbar-nav'>
                            { this.renderUserMenu(this.props.auth) }
                        </ul>
                    </div>
                </nav>

                <main className='container container-fluid app-body'>
                    {routes}
                </main>
            </div>
        );
    }
}

export default withFirebase(MainLayout);