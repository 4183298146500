import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { setUser } from '../actions';

import { default as Component } from '../components/Register';

export default compose(
    connect(({ auth }) => {
        return {
            authExists: !!auth && !!auth.user && !!auth.user.uid
        };
    }, (dispatch) => bindActionCreators({
        setUser
    }, dispatch))
)(Component);