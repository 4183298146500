import { createSelector } from 'reselect';
import { isLoaded } from 'react-redux-firebase';
import moment from 'moment';

import { Categories } from 'data/categories';

const NOT_LOADED = { isLoaded: false };

const getFirestoreData = (state) => {
    if (!state.firestore || !state.firestore.data) {
        return NOT_LOADED;
    }

    return state.firestore.data;
};

const getFirestoreOrderedData = (state) => {
    if (!state.firestore || !state.firestore.ordered) {
        return NOT_LOADED;
    }

    return state.firestore.ordered;
};

const getToday = ({ app: { today } = moment() }) => {
    return today;
};

const getStartOfMonth = ({ app: { startOfMonth } = moment().startOf('month') }) => {
    return startOfMonth;
};

export const getSelectedTransaction = (state) => {
    if (!state || !state.transactions || !state.transactions.selectedTransaction) {
        return;
    }

    return state.transactions.selectedTransaction;
};

export const getTransactions = createSelector(
    [ getFirestoreOrderedData, getStartOfMonth ],
    (data, startOfMonth) => {
        if (!data || !isLoaded(startOfMonth)) {
            return NOT_LOADED;
        }

        const collectionKey = `transactions/${startOfMonth.getTime()}`;
        let transactions = data[collectionKey];

        return isLoaded(transactions) ? transactions : NOT_LOADED;
    }
);

export const getBigExpenses = createSelector(
    [ getFirestoreOrderedData, getStartOfMonth ],
    (data, startOfMonth) => {
        if (!data || !isLoaded(startOfMonth)) {
            return NOT_LOADED;
        }

        const collectionKey = `bigExpenses/${startOfMonth.getTime()}`;
        let bigExpenses = data[collectionKey];

        return isLoaded(bigExpenses) ?
            (bigExpenses || []) : NOT_LOADED;
    }
);

export const getAggregates = createSelector(
    [ getFirestoreOrderedData, getToday, getSelectedTransaction ],
    (data, today, transaction) => {
        if (!isLoaded(data, today, transaction)) {
            return NOT_LOADED;
        }

        let date = transaction.date ?
                transaction.date.toDate ?
                    moment(transaction.date.toDate())
                     : moment(transaction.date, 'YYYY-MM-DD')
                : moment(today);
        let dateKey = date.startOf('month').toDate();
        let key = `monthlyAggregates/${dateKey.getTime()}`;
        let months = data[key] || [];

        return months && months.length ? {
            ...months[0]
        } : null;
    }
);

export const getRecentTransaction = createSelector(
    [ getFirestoreOrderedData, getSelectedTransaction ],
    (data, transaction) => {
        if (!isLoaded(data, transaction)) {
            return NOT_LOADED;
        }

        let suggestionKey = transaction && transaction.description ? `recentTransactions/${transaction.description}` : null;

        let suggestions = data && suggestionKey ? data[suggestionKey] : null;

        return suggestions && suggestions.length ? suggestions[0] : null;
    }
);

export const getBudget = createSelector(
    [ getFirestoreData ],
    (data) => {
        if (!data || !isLoaded(data.budget)) {
            return NOT_LOADED;
        }

        return data.budget;
    }
);

const getMonthlyBudget = createSelector(
    [ getBudget ],
    (budget) => {
        if (!isLoaded(budget)) {
            return NOT_LOADED;
        }

        return budget.monthly;
    }
);

const getDailyBudget = createSelector(
    [ getMonthlyBudget, getToday ],
    (budget, moment) => {
        if (!isLoaded(budget)) {
            return NOT_LOADED;
        }

        return budget / moment.daysInMonth();
    }
);

const getMonth = createSelector(
    [ getToday ],
    (today) => {
        // Get array of numbers for each day of the month
        let days = [...Array(today.daysInMonth()).keys()];
        // Create a moment object for each day and format it
        let dayArray = days.map(day => {
            let date = moment(today).date(day+1);
            return {
                mdate: date
            };
        });
        return dayArray;
    }
);

const getTransactionsForDate = (transactions, date) => {
    return transactions != null ? transactions.filter(t => t != null).map(t => {
        return {
            ...t,
            date: t && t.date && t.date.toDate ? moment(t.date.toDate()) : null
        };
    }).filter(t => t.date.isSame(date, 'day')) : null;
};

const getBigExpensesForDate = (bigExpenses, date) => {
    return bigExpenses != null ? bigExpenses.filter(b => b != null).map(b => {
        return {
            ...b,
            startDate: b.startDate ? moment(b.startDate.toDate()) : null,
            endDate: b.endDate ? moment(b.endDate.toDate()) : null
        };
    }).filter(b => date.isBetween(b.startDate, b.endDate, null, '[]')) : null;
};

export const getDays = createSelector(
    [ getTransactions, getMonth, getDailyBudget, getBigExpenses, getToday ],
    (transactions, days, dailyBudget, bigExpenses, today) => {
        if (!isLoaded(dailyBudget, transactions, bigExpenses)) {
            return NOT_LOADED;
        }

        if (!days || !days.length || !dailyBudget) {
            return;
        }

        days = days.map(d => {
            return { ...d };
        });

        days.reduce((currentBudget, day) => {
            let daysTransactions = getTransactionsForDate(transactions, day.mdate);
            let expenses = daysTransactions != null ? daysTransactions.reduce((c, n) => c + (+n.amountBase), 0) : 0;

            let daysBigExpenses = getBigExpensesForDate(bigExpenses, day.mdate);
            let bigExpenseTotal = daysBigExpenses != null ? daysBigExpenses.reduce((c, n) => c + (+n.dailyAmount), 0) : 0;

            let balance = currentBudget + dailyBudget - expenses - bigExpenseTotal;

            day.balance = balance;
            day.budget = dailyBudget - bigExpenseTotal;
            day.bigExpenses = bigExpenseTotal;
            day.actual = expenses;
            day.number = day.mdate.date();
            day.title = day.mdate.format('MMMM D');
            day.isToday = day.mdate.isSame(today, 'day');

            return balance;
        }, 0);

        /* Return a copy of `days` because when this selector re-runs we should be producing a new
        /  result as the selector `getMonth` only produces a new array reference when `today` changes
        /  and thus when `today` hasn't changed but `transactions` has, the result is the same array
        /  so redux ignores the update. */
        return [...days];
    }
);

export const getTodaysDetails = createSelector(
    [ getDays, getToday ],
    (days, today) => {
        if (!isLoaded(days)) {
            return NOT_LOADED;
        }

        if (!days || !days.length || !today) {
            return;
        }

        return days[today.date() - 1];
    }
);

export const getMonthsDetails = createSelector(
    [ getDays, getToday, getMonthlyBudget, getTodaysDetails ],
    (days, today, budget, todaysDetails) => {
        if (!isLoaded(days, budget, todaysDetails)) {
            return NOT_LOADED;
        }

        if (!days || !days.length || !today || !budget || !todaysDetails) {
            return;
        }

        let throughToday = days.filter(d => d.mdate.isSameOrBefore(today));
        let afterToday = days.filter(d => d.mdate.isAfter(today));

        let spendThroughToday = throughToday.reduce((total, current) => total + (+current.actual), 0);
        let avgDailySpend = spendThroughToday / throughToday.length;
        let projected = afterToday.reduce((total, current) => total + (current.budget - avgDailySpend), todaysDetails.balance);

        return {
            eomBalance: days[days.length - 1].balance,
            remaining: days.filter(d => d.mdate.isAfter(today))
                .reduce((total, current) => total + (+current.actual), 0),
            avgDailySpend,
            projected,
            budget,
            spendThroughToday,
            availableBudget: todaysDetails.balance,
            percentMonthSpent: parseInt((1 - (today.date() / today.daysInMonth())) * 100, 10)
        };
    }
);

export const getProgress = createSelector(
    [ getMonthsDetails, getMonthlyBudget ],
    (monthDetails, budget) => {
        if (!isLoaded(monthDetails, budget)) {
            return NOT_LOADED;
        }

        if (!monthDetails || !budget) {
            return;
        }

        return Math.min(parseInt(((budget - monthDetails.spendThroughToday) / budget)*100, 10), 100);
    }
);

export const getMonthProjection = createSelector(
    [ getDays, getToday, getTodaysDetails, getMonthsDetails ],
    (days, today, todaysDetails, monthsDetails) => {
        if (!isLoaded(days, today, todaysDetails, monthsDetails)) {
            return NOT_LOADED;
        }

        let result = [...days];

        result = result.map(d => {
            return {...d};
        });

        result.filter(d => d.mdate.isAfter(today, 'day')).reduce((balance, nextDay) => {
            nextDay.balance = (balance + nextDay.budget - monthsDetails.avgDailySpend);
            return nextDay.balance;
        }, todaysDetails.balance);

        return result;
    }
);

export const getPointColors = createSelector(
    [ getDays ],
    (days) => {
        if (!isLoaded(days)) {
            return NOT_LOADED;
        }

        return days.map(d => d.isToday ? '#343a40' : 'transparent');
    }
);

const getMonthsAggregates = createSelector(
    [ getFirestoreOrderedData, getStartOfMonth ],
    (data, startOfMonth) => {
        if (!data || !isLoaded(startOfMonth)) {
            return NOT_LOADED;
        }

        const collectionKey = `monthlyAggregates/${startOfMonth.getTime()}`;
        let aggregates = data[collectionKey];

        if (!isLoaded(aggregates)) {
            return NOT_LOADED;
        }

        return aggregates && aggregates.length ?
            aggregates[0] : null;
    }
);

const getYearlyAggregates = createSelector(
    [ getFirestoreOrderedData, getToday ],
    (data, today) => {
        if (!data || !isLoaded(today)) {
            return NOT_LOADED;
        }

        const year = today.year();
        const collectionKey = `yearlyAggregates/${year}`;
        let aggregates = data[collectionKey];

        return isLoaded(aggregates) ? (aggregates || []) : NOT_LOADED;
    }
);

const getThisYearsAggregates = createSelector(
    [ getYearlyAggregates, getToday ],
    (years, today) => {
        if (!isLoaded(years, today)) {
            return;
        }

        return years && years.length ?
            (years.find(y => y.year == today.year()) || null) : null;
    }
);

const getLastYearsAggregates = createSelector(
    [ getYearlyAggregates, getToday ],
    (years, today) => {
        if (!isLoaded(years, today)) {
            return;
        }

        return years && years.length ?
            (years.find(y => y.year == today.year() - 1) || null) : null;
    }
);

export const getCategoryAggregates = createSelector(
    [ getMonthsAggregates, getThisYearsAggregates, getLastYearsAggregates ],
    (month, year, lastYear) => {
        if (!isLoaded(month, year, lastYear)) {
            return NOT_LOADED;
        }

        var result = [];

        Categories.filter(c => {
            if ((!month || !month[c]) && (!year || !year[c]) && (!lastYear || !lastYear[c])) {
                return false;
            }

            return true;
        }).forEach(c => {
            result.push({
                category: c,
                thisMonth: month && month[c] ? month[c] : 0,
                thisYear: year && year[`${c}Average`] ? year[`${c}Average`] : 0,
                lastYear: lastYear && lastYear[`${c}Average`] ? lastYear[`${c}Average`] : 0
            });
        });

        return result;
    }
);

export const getThisMonthsAggregates = createSelector(
    [ getMonthsAggregates, getThisYearsAggregates, getLastYearsAggregates ],
    (month, year, lastYear) => {
        if (!isLoaded(month, year, lastYear)) {
            return NOT_LOADED;
        }

        var monthColor = 'rgba(220, 53, 69, .7)';
        var monthData = {
            label: 'This Month',
            data: [],
            backgroundColor: monthColor,
            borderColor: monthColor,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBorderColor: 'transparent',
            pointHoverBackgroundColor: monthColor
        };

        var yearColor = 'rgba(0, 123, 255, .7)';
        var yearData = {
            label: 'This Year (Avg)',
            data: [],
            backgroundColor: yearColor,
            borderColor: yearColor,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBorderColor: 'transparent',
            pointHoverBackgroundColor: yearColor
        };

        var lastYearColor = 'rgba(23, 162, 184, .7)';
        var lastYearData = {
            label: 'Last Year (Avg)',
            data: [],
            backgroundColor: lastYearColor,
            borderColor: lastYearColor,
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBorderColor: 'transparent',
            pointHoverBackgroundColor: lastYearColor
        };

        var labels = [];

        Categories.filter(c => {
            if ((!month || !month[c]) && (!year || !year[c]) && (!lastYear || !lastYear[c])) {
                return false;
            }

            return true;
        }).sort((a, b) => {
            if (!month) {
                return 0;
            }
            return (month[b] || 0) - (month[a] || 0);
        }).slice(0, 5).forEach(c => {
            labels.push(c);
            monthData.data.push(month && month[c] ? month[c] : 0);
            yearData.data.push(year && year[`${c}Average`] ? year[`${c}Average`] : 0);
            lastYearData.data.push(lastYear && lastYear[`${c}Average`] ? lastYear[`${c}Average`] : 0);
        });

        return {
            labels,
            datasets: [
                monthData,
                yearData,
                lastYearData
            ]
        };
    }
);