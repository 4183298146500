import moment from 'moment';

const formatDateForHtml = (date) => {
    let mdate = moment(date);

    if (mdate.isValid()) {
        return mdate.format('YYYY-MM-DD');
    }

    return '';
};

export const getDateOrDefault = (dateProp, defaultValue, setDefaultToEnd) => {
    if (dateProp && dateProp.toDate) {
        return formatDateForHtml(moment(dateProp.toDate()));
    }
    else if (dateProp && typeof(dateProp) === 'string') {
        return moment(dateProp, 'YYYY-MM-DD').isValid() ? dateProp : '';
    }
    else if (dateProp) {
        return formatDateForHtml(dateProp);
    }
    else if (defaultValue) {
        let mdate =  moment(defaultValue);
        if (!mdate.isValid()) {
            return '';
        }
        return formatDateForHtml(setDefaultToEnd ? mdate.endOf('month') : mdate);
    }
    else {
        return '';
    }
};

export const getDateForUpsert = (date, defaultValue, setDefaultToEnd) => {
    if (date && date.toDate) {
        return date.toDate();
    }
    else if (date && typeof(date) === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return moment(date, 'YYYY-MM-DD').toDate();
    }
    else {
        if (!defaultValue || !defaultValue.toDate) {
            return defaultValue;
        }
        let mdate = moment(defaultValue.toDate());
        return setDefaultToEnd ? mdate.endOf('month').toDate() : mdate.toDate();
    }
};