import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Page from 'common/components/Page';

import EditTransaction from '../containers/EditTransaction';
import TransactionList from '../containers/TransactionList';
import { Filters } from '../constants';

class TransactionLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                type: Filters.ALL,
                day: null
            }
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { match: { params } } = nextProps;

        let filter = {
            type: Filters.ALL,
            day: null
        };

        if (params && params.type) {
            filter = {
                type: params.type,
                value: params.type === Filters.DAY ?
                    moment(params.value, 'YYYY-MM-DD') : params.value,
            };
        }

        return {
            ...prevState,
            filter
        };
    }

    render() {
        return (
            <Page title='Transactions' editor={EditTransaction}>
                <TransactionList filter={this.state.filter} />
            </Page>
        );
    }
}

export default withRouter(TransactionLayout);