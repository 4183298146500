import * as t from './actionTypes';

export function setUser(user) {
    return {
        type: t.SET_USER,
        payload: user
    };
}

export function setAuthLoaded(isLoaded) {
    return {
        type: t.SET_AUTHLOADED,
        payload: isLoaded
    };
}