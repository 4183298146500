import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { isLoaded } from 'react-redux-firebase';

import Chart from 'common/components/Chart';
import Loading from 'common/components/Loading';

class MonthGlance extends Component {

    formatTitle = (item, data) => {
        let index = item[0].index;
        let day = this.props.days[index];

        return day.title;
    }

    formatLabel = (item, data) => {
        let label = data.datasets[item.datasetIndex].label || '';

        return `${label}: $${item.yLabel.toFixed(2)}`;
    }

    render() {
        const {
            days,
            monthDetail,
            pointColors
        } = this.props;

        if (!isLoaded(days, monthDetail, pointColors)) {
            return <Loading />;
        }

        return (
            <Chart component={Line}
                data={{
                    labels: days.map(d => d.number),
                    datasets: [
                        {
                            label: 'Spending',
                            data: days.map(d => d.actual),
                            backgroundColor: 'transparent',
                            borderColor: 'rgba(220, 53, 69, .7)',
                            pointBorderColor: pointColors,
                            pointBackgroundColor: pointColors,
                            pointHoverBorderColor: 'rgba(220, 53, 69, .7)',
                            pointHoverBackgroundColor: 'rgba(220, 53, 69, .7)'
                        },
                        {
                            label: 'Budget',
                            data: days.map(d => d.budget),
                            backgroundColor: 'transparent',
                            borderColor: 'rgba(0, 123, 255, .7)',
                            pointBorderColor: pointColors,
                            pointBackgroundColor: pointColors,
                            pointHoverBorderColor: 'rgba(0, 123, 255, .7)',
                            pointHoverBackgroundColor: 'rgba(0, 123, 255, .7)'
                        },
                        {
                            label: 'Avg. Daily Spend',
                            data: days.map(() => monthDetail.avgDailySpend),
                            backgroundColor: 'transparent',
                            borderColor: 'rgba(180, 180, 180, .7)',
                            borderDash: [10, 10],
                            pointBackgroundColor: 'transparent',
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: 'rgba(180, 180, 180, .7)',
                            pointHoverBackgroundColor: 'rgba(180, 180, 180, .7)'
                        }
                    ]
                }} options={{
                    legend: {
                        display: false
                    },
                    elements: {
                        line: {
                            fill: false
                        }
                    },
                    scales: {
                        xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Day of the Month'
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 15,
                                stepSize: 1
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                callback: value => '$' + value
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: this.formatTitle,
                            label: this.formatLabel
                        }
                    }
                }}/>
        );
    }
}

export default MonthGlance;