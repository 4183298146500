import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { getBudget } from 'data/selectors';
import { withAuth } from 'modules/auth';

import { default as Component } from '../components/EditSettings';

const Container = compose(
    withAuth,
    firestoreConnect(({ auth : { user } }) => {
        return [
            {
                collection: 'budget',
                doc: user.uid,
                storeAs: 'budget'
            }
        ];
    }),
    connect((state) => {
        return {
            budget: getBudget(state)
        };
    })
)(Component);

export default Container;