import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { getBigExpenses } from 'data/selectors';
import withToday from 'common/containers/withToday';
import { withAuth } from 'modules/auth';

import { default as Component } from '../components/BigExpenseList';

export default compose(
    withAuth,
    withToday,
    firestoreConnect(({ auth: { user }, startOfMonth, endOfMonth }) => {
        return [
            {
                collection: 'bigExpenses',
                where: [
                    ['ownerId', '==', user.uid],
                    ['startDate', '>=', startOfMonth],
                    ['startDate', '<=', endOfMonth]
                ],
                orderBy: ['startDate'],
                storeAs: `bigExpenses/${startOfMonth.getTime()}`
            }
        ];
    }),
    connect((state) => {
        return {
            bigExpenses: getBigExpenses(state)
        };
    })
)(Component);