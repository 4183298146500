import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { withAuth } from 'modules/auth';

import { default as Component } from '../components/CurrencyList';

export default compose(
    withAuth,
    firestoreConnect(({ auth: { user } }) => {
        return [
            {
                collection: 'currencies',
                where: [
                    ['ownerId', '==', user.uid]
                ],
                orderBy: ['abbreviation']
            }
        ];
    }),
    connect(({ firestore }) => {
        return {
            currencies: firestore.data.currencies
        };
    })
)(Component);