import React, { Component } from 'react';

import Page from 'common/components/Page';

import EditReimbursable from '../containers/EditReimbursable';
import ReimbursableList from '../containers/ReimbursableList';

class ReimbursableLayout extends Component {
    render() {
        return (
            <Page title='Reimbursables' editor={EditReimbursable} disableFilter={true}>
                <ReimbursableList />
            </Page>
        );
    }
}

export default ReimbursableLayout;