import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import { withAuth } from 'modules/auth';

import { createCurrency, editCurrency } from '../actions';

import { default as Component } from '../components/EditCurrency';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createCurrency,
        editCurrency
    }, dispatch);
}

const getSelectedCurrency = (state) => {
    if (!state || !state.currencies || !state.currencies.selectedCurrency) {
        return;
    }

    return state.currencies.selectedCurrency;
};

function mapStateToProps(state) {
    return {
        currency: getSelectedCurrency(state),
        currencies: state.firestore.ordered.currencies
    };
}

const Container = compose(
    withAuth,
    firestoreConnect(({ auth: { user } }) => {
        return [
            {
                collection: 'currencies',
                where: [
                    ['ownerId', '==', user.uid]
                ],
                orderBy: ['abbreviation']
            }
        ];
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Component);

export default Container;