import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withAuth } from 'modules/auth';
import  withToday from 'common/containers/withToday';

import { createReimbursable, editReimbursable } from '../actions';

import { default as Component } from '../components/EditReimbursable';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createReimbursable,
        editReimbursable
    }, dispatch);
}

const getSelectedReimbursable = (state) => {
    if (!state || !state.reimbursables || !state.reimbursables.selectedReimbursable) {
        return;
    }

    return state.reimbursables.selectedReimbursable;
};

function mapStateToProps(state) {
    return {
        reimbursable: getSelectedReimbursable(state)
    };
}

const Container = compose(
    withAuth,
    withToday,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Component);

export default Container;