import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { formatCurrency } from 'utilities/formatters';

class CategoryAggregateItem extends Component {

    static formatAmount (amt, target) {
        let amtFormatted = formatCurrency(amt);

        amt = amt || 0;

        let className = classNames({
            'text-success': (!target && amt >= 0) || (target && amt <= target),
            'text-danger': (!target && amt < 0) || (target && amt > target)
        });

        return (
            <span className={className}>{amtFormatted}</span>
        );
    }

    viewTransactions = () => {
        const { category, history } = this.props;

        history.push(`/transactions/category/${category.category}`);
    }

    render() {
        let { category } = this.props;

        return (
            <tr className='clickable' onClick={this.viewTransactions}>
                <th scope='row'>{category.category}</th>
                <td>{CategoryAggregateItem.formatAmount(category.thisMonth, category.thisYear)}</td>
                <td>{CategoryAggregateItem.formatAmount(category.thisYear, category.lastYear)}</td>
                <td style={{ color: '#555' }}>{formatCurrency(category.lastYear)}</td>
            </tr>
        );
    }
}

export default withRouter(CategoryAggregateItem);