export const Categories = [
    'Adjustment',
    'Dating',
    'Donations',
    'Lunches',
    'Gifts',
    'Groceries',
    'Health',
    'Shopping',
    'Socializing',
    'Travel',
    'Work'
];