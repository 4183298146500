import app from 'firebase/app';
import auth from 'firebase/auth';
import firestore from 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyCl4kVECQBxGwt2TnSuFJdXpOvysP8y4XU',
    authDomain: 'budget-2494a.firebaseapp.com',
    databaseURL: 'https://budget-2494a.firebaseio.com',
    projectId: 'budget-2494a',
    storageBucket: 'budget-2494a.appspot.com',
    messagingSenderId: '181497748376'
};

app.initializeApp(config);

app.firestore().settings({ timestampsInSnapshots: true });

export default app;