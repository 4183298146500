import { compose } from 'redux';
import { connect } from 'react-redux';

import { getDays, getMonthsDetails, getPointColors } from 'data/selectors';

import { default as Component } from '../components/MonthGlance';

export default compose(
    connect((state) => {
        return {
            days: getDays(state),
            monthDetail: getMonthsDetails(state),
            pointColors: getPointColors(state)
        };
    })
)(Component);