import React, { Component } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import BalanceForecast from '../containers/BalanceForecast';
import CategoryAggregates from '../containers/CategoryAggregates';
import Currency from './Currency';
import DashboardPane from './DashboardPane';
import DashboardPaneItem from './DashboardPaneItem';
import MonthCategories from '../containers/MonthCategories';
import MonthGlance from '../containers/MonthGlance';
import Progress from './Progress';
import Loading from 'common/components/Loading';
import DateFilter from 'common/containers/DateFilter';

class DashboardLayout extends Component {
    viewSpending = () => {
        const { history } = this.props;

        history.push('/transactions/today');
    }

    render() {
        let {
            days,
            progress,
            todaysDetail,
            monthDetail
        } = this.props;

        if (!isLoaded(days, progress, todaysDetail, monthDetail)) {
            return <Loading />;
        }

        return (
            <div className='mb-4'>
                <Helmet>
                    <title>Budget - Dashboard</title>
                </Helmet>

                <header className='row'>
                    <div className='col-12 col-sm-7 col-lg-9'>
                        <h1 className='display-4'>Dashboard</h1>
                    </div>
                    <div className='col-12 col-sm-5 col-lg-3'>
                        <DateFilter />
                    </div>
                </header>

                <article className='row mt-4'>
                    <div className='col-12'>
                        <Progress label={`${progress}% of Budget Remaining`} value={progress} />
                        <Progress label={`${monthDetail.percentMonthSpent}% of Month Remaining`} value={monthDetail.percentMonthSpent} />
                    </div>
                </article>

                <article className='row mt-2'>
                    <DashboardPane title="Today's Summary">
                        <DashboardPaneItem label="Today's Balance">
                            <Currency value={todaysDetail.balance} doHighlight={true} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label="Today's Allotted Spend">
                            <Currency value={todaysDetail.budget} doHighlight={false} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label="Today's Actual Spend">
                            <Currency value={todaysDetail.actual} customHighlighter={{
                                'clickable': true,
                                'text-primary': todaysDetail.actual <= todaysDetail.budget,
                                'text-danger': todaysDetail.actual > todaysDetail.budget
                            }} onClick={this.viewSpending} />
                        </DashboardPaneItem>
                    </DashboardPane>

                    <DashboardPane title='Month Summary'>
                        <DashboardPaneItem label='End of Month Balance as of Today'>
                            <Currency value={monthDetail.eomBalance} doHighlight={true} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label='Remaining Estimated Spend'>
                            <Currency value={monthDetail.remaining} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label='Projected End of Month Balance'>
                            <Currency value={monthDetail.projected} customHighlighter={{
                                'text-success': monthDetail.projected >= 0,
                                'text-danger': monthDetail.projected < 0
                            }} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label='Current Spending this Month'>
                            <Currency value={monthDetail.spendThroughToday} customHighlighter={{
                                'text-success': monthDetail.spendThroughToday <= monthDetail.budget,
                                'text-danger': monthDetail.spendThroughToday > monthDetail.budget
                            }} />
                        </DashboardPaneItem>

                        <DashboardPaneItem label='Average Daily Spend'>
                            <Currency value={monthDetail.avgDailySpend} />
                        </DashboardPaneItem>
                    </DashboardPane>
                </article>

                <article className='row mt-2'>
                    <DashboardPane title='Month at a Glance' size={12}>
                        <MonthGlance />
                    </DashboardPane>
                </article>

                <article className='row mt-2'>
                    <DashboardPane title='Balance Forecast' size={12}>
                        <BalanceForecast />
                    </DashboardPane>
                </article>

                <article className='row mt-2'>
                    <DashboardPane title='Spending by Category'>
                        <CategoryAggregates />
                    </DashboardPane>

                    <DashboardPane title='Top 5 Categories'>
                        <MonthCategories />
                    </DashboardPane>
                </article>
            </div>
        );
    }
}

export default DashboardLayout;