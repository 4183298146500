import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// import ReactGA from 'react-ga';

// initialize spa analytics
// ReactGA.initialize('UA-100037-7');

var lastLocation = null;

function track() {
    // if (window.location.pathname === lastLocation) {
    //     return;
    // }

    // if (process.env.NODE_ENV === 'development') {
    //     console.info(`Navigated to: ${window.location.pathname}`);
    // }
    // else {
    //     ReactGA.set({ page: window.location.pathname });
    //     ReactGA.pageview(window.location.pathname);
    // }
}

class TrackedRouter extends Component {
    componentWillMount() {
        // track();
    }

    componentWillUpdate() {
        // track();
    }

    render() {
        return (
            <Route path="/" component={ this.props.component } />
        );
    }
}

export default TrackedRouter;