import React from 'react';
import classNames from 'classnames';

import { formatCurrency } from 'utilities/formatters';

export default ({ value, doHighlight, customHighlighter, onClick }) => {
    value = value || 0;

    let className = null;

    if (customHighlighter) {
        className = classNames(customHighlighter);
    }
    else {
        className = classNames({
            'text-primary': !doHighlight,
            'text-success': doHighlight && value >= 0,
            'text-danger': doHighlight && value < 0
        });
    }

    return (
        <h4 className={className} onClick={onClick}>{formatCurrency(value)}</h4>
    );
};