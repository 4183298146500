import React, { Component } from 'react';
import { withFirestore } from 'react-redux-firebase';
import classNames from 'classnames';
import moment from 'moment';

import GridActions from 'common/components/GridActions';

import { formatCurrency } from 'utilities/formatters';

class ReimbursableListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    edit = () => {
        this.props.editReimbursable({
            id: this.props.id,
            ...this.props.reimbursable
        });
    }

    remove = async () => {
        if (!confirm('Delete this item?')) {
            return;
        }

        this.setState({
            isBusy: true
        });

        let { id } = this.props;
        await this.props.firestore.delete({ collection: 'reimbursables', doc: id });
    }

    formatRepaid = (isRepaid) => {
        let className = classNames({
            'text-success': isRepaid,
            'text-danger': !isRepaid
        });

        return (
            <span className={className}>{isRepaid ? 'Yes' : 'No'}</span>
        );
    }

    formatExpectedDate = (reimbursable) => {
        let date = moment(reimbursable.expectedDate.toDate());
        let overdue = date.isBefore(moment());

        let className = classNames({
            'text-danger': !reimbursable.repaid && overdue
        });

        return (
            <span className={className}>{date.format('MM/DD/YYYY')}</span>
        );
    }

    render() {
        let { reimbursable } = this.props;

        if (!reimbursable || !reimbursable.date) {
            return null;
        }

        let mdate = moment(reimbursable.date.toDate());

        return (
            <tr>
                <th scope='row' title={mdate.format('dddd')}>{mdate.format('MM/DD/YYYY')}</th>
                <td>{reimbursable.description}</td>
                <td>{formatCurrency(reimbursable.amount)}</td>
                <td>{this.formatRepaid(reimbursable.repaid)}</td>
                <td>{reimbursable.repayer}</td>
                <td>{this.formatExpectedDate(reimbursable)}</td>
                <GridActions isBusy={this.state.isBusy} onEdit={this.edit} onDelete={this.remove} />
            </tr>
        );
    }
}

export default withFirestore(ReimbursableListItem);