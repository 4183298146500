import * as t from './actionTypes';

export function createTransaction(defaults) {
    return {
        type: t.NEW_TRANSACTION,
        payload: defaults || {}
    };
}

export function editTransaction(transaction) {
    return {
        type: t.EDIT_TRANSACTION,
        payload: transaction
    };
}