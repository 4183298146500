import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { formatCurrency } from 'utilities/formatters';

class ForecastListItem extends Component {

    formatAmount = (amt, max) => {
        let amtFormatted = formatCurrency(amt);

        amt = amt || 0;

        let className = classNames({
            'text-success': (!max && amt >= 0) || (max && amt <= max),
            'text-danger': (!max && amt < 0) || (max && amt > max)
        });

        return (
            <span className={className}>{amtFormatted}</span>
        );
    }

    viewTransactions = () => {
        const { day, history } = this.props;

        history.push(`/transactions/day/${moment(day.mdate).format('YYYY-MM-DD')}`);
    }

    render() {
        let { day } = this.props;

        let className = classNames('clickable', {
            'table-info': day.isToday
        });

        return (
            <tr className={className} onClick={this.viewTransactions}>
                <th scope='row' title={day.mdate.format('dddd')}>{day.title}</th>
                <td>{this.formatAmount(day.balance)}</td>
                <td>{this.formatAmount(day.budget)}</td>
                <td>{this.formatAmount(day.bigExpenses, day.budget)}</td>
                <td>{this.formatAmount(day.actual, day.budget)}</td>
            </tr>
        );
    }
}

export default withRouter(ForecastListItem);