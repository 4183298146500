import React, { Component } from 'react';
import { isLoaded } from 'react-redux-firebase';

class Currency extends Component {

    onValueChanged = (e) => {
        let { value, currency, currencies } = this.props;
        let property = e.target.dataset.property;

        if (property === 'value') {
            value = (+e.target.value) || 0;
        }
        else if (property === 'currency') {
            currency = e.target.value;
        }

        currency = currency || 'USD';

        let selectedCurrency = currencies.filter(c => c.abbreviation === currency)[0];

        let amountBase = value * selectedCurrency.conversion;

        this.props.onChange({
            amount: value,
            currency,
            amountBase
        });
    }

    render() {
        const { value, currency, currencies, id } = this.props;
        const isNotLoaded = !isLoaded(currencies);

        return (
            <div className='input-group input-group-sm'>
                <div className='input-group-prepend'>
                    <span className='input-group-text'>$</span>
                </div>
                <input type='number' className='form-control' id={id} data-property='value' step='0.01' value={value || ''} onChange={this.onValueChanged} required disabled={isNotLoaded} />
                <div className='input-group-append'>
                    <select className='form-control form-control-sm input-group-select' data-property='currency' value={currency != null ? currency : 'USD'} onChange={this.onValueChanged} disabled={isNotLoaded}>
                        {(isNotLoaded ? [] : currencies).map(c => (
                            <option key={c.abbreviation} value={c.abbreviation}>
                                {c.abbreviation}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }
}

export default Currency;