import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from './Spinner';

export default ({ onEdit, onDelete, isBusy }) => (
    <td className='grid-actions'>
        <div className='btn-group btn-group-sm' style={isBusy ? { visibility: 'hidden' } : null}>
            <button onClick={onEdit} className='btn btn-primary'>
                <FontAwesomeIcon icon={['fas', 'edit']} />
            </button>
            <button onClick={onDelete} className='btn btn-danger'>
                <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </button>
        </div>
        { isBusy ? (
            <div className='loading-cover'>
                <Spinner />
            </div>
        ): null }
    </td>
);