import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import moment from 'moment';

import withToday from 'common/containers/withToday';
import { withAuth } from 'modules/auth';
import { getAggregates, getSelectedTransaction, getRecentTransaction } from 'data/selectors';

import { createTransaction, editTransaction } from '../actions';

import { default as Component } from '../components/EditTransaction';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createTransaction,
        editTransaction
    }, dispatch);
}

const Container = compose(
    withAuth,
    withToday,
    connect((state) => {
        return {
            transaction: getSelectedTransaction(state)
        };
    }),
    firestoreConnect(({ auth : { user }, transaction, today }) => {
        let date = transaction.date ?
                transaction.date.toDate ?
                    moment(transaction.date.toDate())
                     : moment(transaction.date, 'YYYY-MM-DD')
                : moment(today);
        let dateKey = date.startOf('month').toDate();
        let todayKey = moment(today).toDate();

        return [
            {
                collection: 'monthlyAggregates',
                where: [
                    ['ownerId', '==', user.uid],
                    ['month', '==', dateKey]
                ],
                limit: 1,
                storeAs: `monthlyAggregates/${dateKey.getTime()}`
            },
            {
                collection: 'transactions',
                where: [
                    ['ownerId', '==', user.uid],
                    ['date', '<', moment(todayKey).add(-1, 'days').toDate()],
                    ['description', '==', transaction.description]
                ],
                orderBy: [['date', 'desc']],
                limit: 1,
                storeAs: `recentTransactions/${transaction.description}`
            }
        ];
    }),
    connect((state) => {
        return {
            aggregates: getAggregates(state),
            recentTransaction: getRecentTransaction(state)
        };
    }, mapDispatchToProps)
)(Component);

export default Container;