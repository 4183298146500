import * as t from './actionTypes';

export function createCurrency(defaults) {
    return {
        type: t.NEW_CURRENCY,
        payload: defaults || {}
    };
}

export function editCurrency(record) {
    return {
        type: t.EDIT_CURRENCY,
        payload: record
    };
}