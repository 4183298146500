import React from 'react';

import RequireAuth from './containers/RequireAuth';

export default function requireAuth(Component) {
    return () => (
        <RequireAuth>
            <Component />
        </RequireAuth>
    );
}