import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withAuth } from 'modules/auth';
import { setUser } from 'modules/auth/actions';

import { edit } from '../actions';

import { default as Component } from '../components/EditProfile';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        edit,
        setUser
    }, dispatch);
}

function mapStateToProps({ auth, profile }) {
    if (auth && auth.user && auth.user.uid) {
        profile.name = profile.name != null ? profile.name : auth.user.displayName;
        profile.email = profile.email != null ? profile.email : auth.user.email;
    }

    return {
        profile
    };
}

const Container = compose(
    withAuth,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Component);

export default Container;