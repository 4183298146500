import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';

import Loading from 'common/components/Loading';

class EditProfile extends Component {

    constructor() {
        super();

        this.state = {
            profileError: null,
            emailError: null,
            passwordError: null,
            isProfileBusy: false,
            isEmailBusy: false,
            isPasswordBusy: false
        };
    }

    saveProfile = async (e) => {
        const { firebase, setUser } = this.props;

        let p = this.props.profile;
        let user = firebase.auth().currentUser;

        e.preventDefault();

        if (p.name === user.displayName) {
            return;
        }

        this.setState({
            isProfileBusy: true
        });

        try {
            let result = await user.updateProfile({
                displayName: p.name
            });

            setUser({
                displayName: p.name
            });

            this.setState({
                profileError: null
            });
        }
        catch (e) {
            this.setState({
                profileError: e.message
            });
        }
        finally {
            this.setState({
                isProfileBusy: false
            });
        }
    }

    changeEmail = async (e) => {
        const { firebase, setUser, edit } = this.props;

        let p = this.props.profile;
        let user = firebase.auth().currentUser;

        e.preventDefault();

        if (p.email === user.email) {
            return;
        }

        this.setState({
            isEmailBusy: true
        });

        try {
            let authResult = await firebase.auth().signInWithEmailAndPassword(user.email, p.currentPassword);

            let result = await user.updateEmail(p.email);

            setUser({
                email: p.email
            });

            edit({
                currentPassword: ''
            });

            this.setState({
                emailError: e.message
            });
        }
        catch (e) {
            this.setState({
                emailError: e.message
            });
        }
        finally {
            this.setState({
                isEmailBusy: false
            });
        }
    }

    updatePassword = async (e) => {
        const { firebase, edit } = this.props;

        let p = this.props.profile;
        let user = firebase.auth().currentUser;

        e.preventDefault();

        if (p.password && p.password === '') {
            return;
        }

        this.setState({
            isPasswordBusy: true
        });

        try {
            let authResult = await firebase.auth().signInWithEmailAndPassword(p.email, p.oldPassword);

            let result = await user.updatePassword(p.password);
            console.log(result);

            edit({
                oldPassword: '',
                password: ''
            });

            this.setState({
                passwordError: e.message
            })
        }
        catch (e) {
            this.setState({
                passwordError: e.message
            });
        }
        finally {
            this.setState({
                isPasswordBusy: false
            });
        }
    }

    onValueChanged = (e) => {
        let update = {
            ...this.props.profile
        };

        let prop = e.target.dataset.property;

        update[prop] = e.target.value;

        this.props.edit(update);
    }

    render() {
        let { profile } = this.props;

        return (
            <div>
                <form onSubmit={this.saveProfile}>
                    <div className='form-group form-row'>
                        <label htmlFor='name' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Name</label>
                        <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='name' data-property='name' value={profile.name || ''} onChange={this.onValueChanged} required />
                    </div>

                    {
                        this.state.profileError ? (
                            <div className='form-group row'>
                                <span className='col-12 text-danger'>{this.state.profileError}</span>
                            </div>
                        ) : null
                    }

                    {!this.state.isProfileBusy ? (
                        <button type='submit' className='btn btn-primary' onClick={this.saveProfile}>Update Profile</button>
                    ) : <Loading />}
                </form>

                <hr />

                <form onSubmit={this.changeEmail}>
                    <div className='form-group form-row'>
                        <label htmlFor='email' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Email</label>
                        <input type='email' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='email' data-property='email' value={profile.email || ''} onChange={this.onValueChanged} required />
                    </div>

                    <div className='form-group form-row'>
                        <label htmlFor='currentPassword' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Current Password</label>
                        <input type='password' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='currentPassword' data-property='currentPassword' value={profile.currentPassword || ''} onChange={this.onValueChanged} required />
                    </div>

                    {
                        this.state.emailError ? (
                            <div className='form-group row'>
                                <span className='col-12 text-danger'>{this.state.emailError}</span>
                            </div>
                        ) : null
                    }

                    {!this.state.isEmailBusy ? (
                        <button type='submit' className='btn btn-primary' onClick={this.changeEmail}>Change Email</button>
                    ) : <Loading />}
                </form>

                <hr />

                <form onSubmit={this.updatePassword}>

                    <div className='form-group form-row'>
                        <label htmlFor='oldPassword' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Current Password</label>
                        <input type='password' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='oldPassword' data-property='oldPassword' value={profile.oldPassword || ''} onChange={this.onValueChanged} required />
                    </div>

                    <div className='form-group form-row'>
                        <label htmlFor='password' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>New Password</label>
                        <input type='password' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='password' data-property='password' value={profile.password || ''} onChange={this.onValueChanged} required />
                    </div>

                    {
                        this.state.passwordError ? (
                            <div className='form-group row'>
                                <span className='col-12 text-danger'>{this.state.passwordError}</span>
                            </div>
                        ) : null
                    }

                    {!this.state.isPasswordBusy ? (
                        <button type='submit' className='btn btn-primary' onClick={this.updatePassword}>Change Password</button>
                    ) : <Loading />}
                </form>
            </div>
        );
    }
}

export default withFirebase(EditProfile);