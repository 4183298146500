import * as t from './actionTypes';

const initialState = {};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.EDIT:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};