import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { editCurrency } from '../actions';

import { default as Component } from '../components/CurrencyListItem';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        editCurrency
    }, dispatch);
}

const Container = compose(
    connect(
        null,
        mapDispatchToProps
    )
)(Component);

export default Container;