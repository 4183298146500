import React from 'react';
import { isLoaded } from 'react-redux-firebase';

import Loading from 'common/components/Loading';
import ForecastListItem from './ForecastListItem';

export default ({ days }) => {
    if (!isLoaded(days)) {
        return <Loading />;
    }

    return (
        <table className='table table-striped table-responsive-sm'>
            <thead>
                <tr>
                    <th scope='col'>Date</th>
                    <th scope='col'>Balance</th>
                    <th scope='col'>Budget</th>
                    <th scope='col'>Big Expense</th>
                    <th scope='col'>Spending</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(days).map((k) => <ForecastListItem day={days[k]} key={k} />)}
            </tbody>
        </table>
    );
};