import React, { Component } from 'react';

import Page from 'common/components/Page';

import EditBigExpense from '../containers/EditBigExpense';
import BigExpenseList from '../containers/BigExpenseList';

class BigExpenseLayout extends Component {
    render() {
        return (
            <Page title='Big Expenses' editor={EditBigExpense}>
                <BigExpenseList />
            </Page>
        );
    }
}

export default BigExpenseLayout;