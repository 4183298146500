import React, { Component } from 'react';
import moment from 'moment';
import { isLoaded, withFirestore } from 'react-redux-firebase';

import FormActions from 'common/components/FormActions';
import Loading from 'common/components/Loading';

import { getDateOrDefault, getDateForUpsert } from 'utilities/date';

class EditReimbursable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    save = async (e) => {
        let s = this.props.reimbursable;
        let { user } = this.props.auth;

        e.preventDefault();

        if (s.amount == null || !s.description || !s.repayer) {
            return false;
        }

        this.setState({
            isBusy: true
        });

        let amount = (+s.amount);
        amount = isNaN(amount) ? 0 : amount;

        let date = getDateForUpsert(s.date, this.props.today);
        let expectedDate = getDateForUpsert(s.expectedDate, this.props.today, true);

        let reimbursable = {
            ...this.props.reimbursable,
            date,
            expectedDate,
            ownerId: user.uid
        };

        let id = undefined;

        if (reimbursable.id) {
            id = reimbursable.id;
            delete reimbursable.id;
        }

        if (id) {
            await this.props.firestore.set({ collection: 'reimbursables', doc: id }, reimbursable);
        }
        else {
            await this.props.firestore.add({ collection: 'reimbursables' }, reimbursable);
        }

        this.props.createReimbursable();

        this.setState({
            isBusy: false
        });

        this.description.focus();
    }

    cancel = (e) => {
        e.preventDefault();

        this.props.createReimbursable();
    }

    onValueChanged = (e) => {
        let update = {
            ...this.props.reimbursable
        };

        let prop = e.target.dataset.property;

        update[prop] = e.target.value;

        this.props.editReimbursable(update);
    }

    onRepaidChanged = (e) => {
        let update = {
            ...this.props.reimbursable,
            repaid: e.target.checked
        };

        this.props.editReimbursable(update);
    }

    render() {
        let { reimbursable, today } = this.props;

        if (!isLoaded(reimbursable, today)) {
            return <Loading />;
        }

        let date = getDateOrDefault(reimbursable.date, today);
        let expectedDate = getDateOrDefault(reimbursable.expectedDate, today, true);

        return (
            <form onSubmit={this.save}>
                <div className='form-group form-row'>
                    <label htmlFor='date' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Start Date</label>
                    <input type='date' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='date' data-property='date' value={date} onChange={this.onValueChanged} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='description' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Description</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='description' data-property='description' value={reimbursable.description} onChange={this.onValueChanged} ref={(i) => this.description = i} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='amount' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Amount</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <div className='input-group input-group-sm'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>$</span>
                            </div>
                            <input type='number' className='form-control' id='amount' data-property='amount' step='0.01' value={reimbursable.amount} onChange={this.onValueChanged} required />
                        </div>
                    </div>
                </div>
                <div className='form-group from-row'>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2 form-check'>
                        <label htmlFor='repaid' className='form-check-label'>
                            <input type='checkbox' className='form-check-input' id='repaid' data-property='repaid' checked={reimbursable.repaid} onChange={this.onRepaidChanged} required />
                            Repaid?
                        </label>
                    </div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='repayer' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Repayer</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='repayer' data-property='repayer' value={reimbursable.repayer} onChange={this.onValueChanged} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='expectedDate' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Expected Date</label>
                    <input type='date' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='expectedDate' data-property='expectedDate' value={expectedDate} onChange={this.onValueChanged} required />
                </div>

                <hr />

                <FormActions save={this.save} cancel={this.cancel} id={reimbursable.id} isBusy={this.state.isBusy} />
            </form>
        );
    }
}

export default withFirestore(EditReimbursable);