import React, { Component } from 'react';

import Page from 'common/components/Page';

import EditSettings from '../containers/EditSettings';

class SettingsLayout extends Component {
    render() {
        return (
            <Page title='Settings' editor={EditSettings} disableFilter={true} />
        );
    }
}

export default SettingsLayout;