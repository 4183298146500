import React, { Component } from 'react';
import { isLoaded } from 'react-redux-firebase';
import moment from 'moment';
import qs from 'qs';

import Loading from 'common/components/Loading';
import FilterOption from './FilterOption';
import { Filters } from '../constants';
import TransactionListItem from '../containers/TransactionListItem';

class TransactionList extends Component {
    onFilterChanged = (e) => {
        const filter = e.currentTarget.querySelector('input').value;

        if (this.props.filter.type === filter) {
            return;
        }

        this.props.history.push(`/transactions/${filter}`);
    }

    applyFilter = (trx) => {
        const { filter, today } = this.props;

        // Bug in redux-firebase doesn't remove key from collection, just nulls out data on delete
        if (!trx || !trx.date) {
            return false;
        }

        if (filter.type === Filters.ALL) {
            return true;
        }

        let mdate = moment(trx.date.toDate());

        switch (filter.type) {
            case Filters.TODAY:
                return mdate.isSame(today, 'day');
            case Filters.FUTURE:
                return mdate.isSameOrAfter(today, 'day');
            case Filters.ACTUAL:
                return mdate.isSameOrBefore(today, 'day');
            case Filters.DAY:
                return mdate.isSame(filter.value, 'day');
            case Filters.CATEGORY:
                return trx.category === filter.value;
        }
    }

    render() {
        const { filter, transactions } = this.props;

        if (!isLoaded(transactions)) {
            return <Loading />;
        }

        return (
            <div>
                <div className='row'>
                    <div className='col-12 text-right'>
                        <span>View&nbsp;</span>
                        <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                            <FilterOption label='All' value={Filters.ALL} selectedValue={filter.type} onChange={this.onFilterChanged} />
                            <FilterOption label='Today' value={Filters.TODAY} selectedValue={filter.type} onChange={this.onFilterChanged} />
                            <FilterOption label='Actual' value={Filters.ACTUAL} selectedValue={filter.type} onChange={this.onFilterChanged} />
                            <FilterOption label='Future' value={Filters.FUTURE} selectedValue={filter.type} onChange={this.onFilterChanged} />

                            { filter.type === Filters.DAY ? (
                                <FilterOption label={filter.value.format('MMM D')} value={Filters.DAY} selectedValue={filter.type} onChange={this.onFilterChanged} />
                            ) : filter.type === Filters.CATEGORY ? (
                                <FilterOption label={filter.value} value={Filters.CATEGORY} selectedValue={filter.type} onChange={this.onFilterChanged} />
                            ) : null }
                        </div>
                    </div>
                </div>
                <table className='table table-striped table-responsive-sm mt-4'>
                    <thead>
                        <tr>
                            <th scope='col'>Date</th>
                            <th scope='col'>Description</th>
                            <th scope='col'>Amount</th>
                            <th scope='col'>Currency</th>
                            <th scope='col'>Amount Base</th>
                            <th scope='col'>Category</th>
                            <th scope='col'>Note</th>
                            <th scope='col'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions
                            .filter(this.applyFilter)
                            .map((t) => <TransactionListItem transaction={t} key={t.id} />)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TransactionList;