import React, { Component } from 'react';
import moment from 'moment';
import { isLoaded, withFirestore } from 'react-redux-firebase';

import Currency from 'common/containers/Currency';
import FormActions from 'common/components/FormActions';
import Loading from 'common/components/Loading';

import { getDateOrDefault, getDateForUpsert } from 'utilities/date';

class EditBigExpense extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    save = async (e) => {
        let s = this.props.bigExpense;
        let { user } = this.props.auth;

        e.preventDefault();

        if (s.amount == null || !s.description) {
            return false;
        }

        this.setState({
            isBusy: true
        });

        let amount = (+s.amount);
        amount = isNaN(amount) ? 0 : amount;

        let amountBase = (+s.amountBase);
        amountBase = isNaN(amountBase) ? 0 : amountBase;

        let startDate = moment(getDateForUpsert(s.startDate, this.props.today));
        let endDate = moment(getDateForUpsert(s.endDate, this.props.today, true));

        let duration = endDate.diff(startDate, 'days') + 1 || 1;
        let dailyAmount = amountBase / duration;

        let bigExpense = {
            ...s,
            amount,
            amountBase,
            duration,
            dailyAmount,
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            ownerId: user.uid
        };

        let id = undefined;

        if (bigExpense.id) {
            id = bigExpense.id;
            delete bigExpense.id;
        }

        if (id) {
            await this.props.firestore.set({ collection: 'bigExpenses', doc: id }, bigExpense);
        }
        else {
            await this.props.firestore.add({ collection: 'bigExpenses' }, bigExpense);
        }

        this.props.createBigExpense();

        this.setState({
            isBusy: false
        });

        this.description.focus();
    }

    cancel = (e) => {
        e.preventDefault();

        this.props.createBigExpense();
    }

    onValueChanged = (e) => {
        let update = {
            ...this.props.bigExpense
        };

        let prop = e.target.dataset.property;

        update[prop] = e.target.value;

        this.props.editBigExpense(update);
    }

    render() {
        let { bigExpense, today } = this.props;

        if (!isLoaded(bigExpense, today)) {
            return <Loading />;
        }

        let startDate = getDateOrDefault(bigExpense.startDate, today);
        let endDate = getDateOrDefault(bigExpense.endDate, today, true);

        return (
            <form onSubmit={this.saveBigExpense}>
                <div className='form-group form-row'>
                    <label htmlFor='startDate' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Start Date</label>
                    <input type='date' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='startDate' data-property='startDate' value={startDate} onChange={this.onValueChanged} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='endDate' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>End Date</label>
                    <input type='date' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='endDate' data-property='endDate' value={endDate} onChange={this.onValueChanged} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='description' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Description</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='description' data-property='description' value={bigExpense.description} onChange={this.onValueChanged} ref={(i) => this.description = i} required />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='amount' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Amount</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <Currency id='amount' value={bigExpense.amount} currency={bigExpense.currency} onChange={this.props.editBigExpense} />
                    </div>
                </div>

                <hr />

                <FormActions save={this.save} cancel={this.cancel} id={bigExpense.id} isBusy={this.state.isBusy} />
            </form>
        );
    }
}

export default withFirestore(EditBigExpense);