import { compose } from 'redux';
import { connect } from 'react-redux';

import { default as Component } from '../components/Reset';

export default compose(
    connect(({ auth }) => {
        return {
            authExists: !!auth && !!auth.user && !!auth.user.uid
        };
    })
)(Component);