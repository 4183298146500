import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { withAuth } from 'modules/auth';

import { default as Component } from '../components/ReimbursableList';

export default compose(
    withAuth,
    firestoreConnect(({ auth: { user } }) => {
        return [
            {
                collection: 'reimbursables',
                where: [
                    ['ownerId', '==', user.uid]
                ],
                orderBy: ['date']
            }
        ];
    }),
    connect(({ firestore }) => {
        return {
            reimbursables: firestore.data.reimbursables
        };
    })
)(Component);