import { compose } from 'redux';
import { connect } from 'react-redux';

import { default as Component } from '../components/Home';

export default compose(
    connect(({ auth }) => {
        return {
            authExists: !!auth && !!auth.user && !!auth.user.uid,
            isLoaded: !!auth && auth.isLoaded
        };
    })
)(Component);