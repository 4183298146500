import React, { Component } from 'react';
import moment from 'moment';

class DateFilter extends Component {
    onChange = (e) => {
        let mdate = moment(e.target.value, 'YYYY-MM-DD');

        if (mdate.isValid()) {
            this.props.setToday(moment(e.target.value));
        }
    }

    render() {
        const { today } = this.props;

        return (
            <div className='h-100 d-flex align-items-center'>
                <div className='input-group input-group-sm'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text'>Time travel to</span>
                    </div>
                    <input type='date' className='form-control form-control-sm' value={moment(today).format('YYYY-MM-DD')} onChange={this.onChange} required />
                </div>
            </div>
        );
    }
}

export default DateFilter;