import React, { Component } from 'react';

import Page from 'common/components/Page';

import EditCurrency from '../containers/EditCurrency';
import CurrencyList from '../containers/CurrencyList';

class ReimbursableLayout extends Component {
    render() {
        return (
            <Page title='Currencies' editor={EditCurrency} disableFilter={true}>
                <CurrencyList />
            </Page>
        );
    }
}

export default ReimbursableLayout;