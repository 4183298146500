import React from 'react';
import { Redirect } from 'react-router-dom';

import Loading from 'common/components/Loading';

export default ({ auth, children, location }) => {
    if (auth && !auth.isLoaded) {
        return <Loading />;
    }

    if (auth && auth.isLoaded && (!auth.user || !auth.user.uid)) {
        return <Redirect to={`/login?redirect=${location.pathname}`} />;
    }

    return children;
};