import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { requireAuth, Login, Register, Reset } from '../auth';

import { BigExpensesLayout } from '../bigExpenses';
import { CurrencyLayout } from '../currencies';
import { DashboardLayout } from '../dashboard';
import { ForecastLayout } from '../forecast';
import { Home } from '../public';
import { ProfileLayout } from '../profile';
import { ReimbursableLayout } from '../reimbursables';
import { SettingsLayout } from '../settings';
import { TransactionLayout } from '../transactions';

export default (
    <Switch>
        <Route exact path="/login" component={ Login } />
        <Route exact path="/register" component={ Register } />
        <Route exact path="/reset" component={ Reset } />
        <Route exact path="/currencies" component={ requireAuth(CurrencyLayout) } />
        <Route exact path="/profile" component={ requireAuth(ProfileLayout) } />
        <Route exact path="/settings" component={ requireAuth(SettingsLayout) } />
        <Route exact path="/bigExpenses" component={ requireAuth(BigExpensesLayout) } />
        <Route exact path="/forecast" component={ requireAuth(ForecastLayout) } />
        <Route exact path="/reimbursables" component={ requireAuth(ReimbursableLayout) } />
        <Route path="/transactions/:type?/:value?" component={ requireAuth(TransactionLayout) } />
        <Route exact path="/dashboard" component={ requireAuth(DashboardLayout) } />
        <Route exact path="/" component={ Home } />
    </Switch>
);