import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import withToday from 'common/containers/withToday';
import { getTransactions } from 'data/selectors';
import { withAuth } from 'modules/auth';

import { default as Component } from '../components/TransactionList';

export default compose(
    withAuth,
    withToday,
    withRouter,
    firestoreConnect(({ auth : { user }, startOfMonth, endOfMonth }) => {
        return [
            {
                collection: 'transactions',
                where: [
                    ['ownerId', '==', user.uid],
                    ['date', '>=', startOfMonth],
                    ['date', '<=', endOfMonth],
                ],
                orderBy: ['date'],
                storeAs: `transactions/${startOfMonth.getTime()}`
            }
        ];
    }),
    connect((state) => {
        return {
            transactions: getTransactions(state)
        };
    })
)(Component);