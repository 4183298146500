import React, { Component } from 'react';
import { isLoaded } from 'react-redux-firebase';

import Loading from 'common/components/Loading';

import CategoryAggregateItem from './CategoryAggregateItem';

class CategoryAggregates extends Component {

    render() {
        const {
            categoryAggregates
        } = this.props;

        if (!isLoaded(categoryAggregates)) {
            return <Loading />;
        }

        return (
            <table className='table table-striped table-responsive-sm'>
                <thead>
                    <tr>
                        <th scope='col'>Category</th>
                        <th scope='col'>This Month</th>
                        <th scope='col'>This Year (Avg)</th>
                        <th scope='col'>Last Year (Avg)</th>
                    </tr>
                </thead>
                <tbody>
                    {categoryAggregates.map(c => <CategoryAggregateItem category={c} key={c.category} />)}
                </tbody>
            </table>
        );
    }
}

export default CategoryAggregates;