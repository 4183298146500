import React from 'react';
import DateFilter from '../containers/DateFilter';
import { Helmet } from 'react-helmet';

export default ({ title, children, editor, disableFilter }) => {
    const Editor = editor;

    return (
        <div>
            <Helmet>
                <title>Budget - {title}</title>
            </Helmet>
            <header className='row mb-4'>
                <div className='col-12 col-sm-7 col-lg-9'>
                    <h1 className='display-4'>{title}</h1>
                </div>
                {!disableFilter ? (
                    <div className='col-12 col-sm-5 col-lg-3'>
                        <DateFilter />
                    </div>
                ) : null }
            </header>

            { editor ? (
                <article className='row mb-4'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-body'>

                                <Editor />

                            </div>
                        </div>
                    </div>
                </article>
            ) : null }

            <article className='row'>
                <div className='col-12'>

                    { children }

                </div>
            </article>
        </div>
    );
};