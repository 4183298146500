import React, { Component } from 'react';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Loading from 'common/components/Loading';

class Register extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: '',
            friendlyName: '',
            isDone: false,
            isBusy: false
        };
    }

    register = async (e) => {
        e.preventDefault();

        if (!this.state.email || !this.state.password || !this.state.friendlyName) {
            this.setState({
                error: 'All fields are required.'
            });
            return;
        }

        this.setState({
            isBusy: true
        });

        const { firebase, firestore } = this.props;

        try {
            let createResult = await firebase.createUser({
                email: this.state.email,
                password: this.state.password
            });

            await createResult.user.updateProfile({
                displayName: this.state.friendlyName
            });

            // Seed a default budget
            await firestore.set({
                collection: 'budget',
                doc: createResult.user.uid
            }, {
                income: 1000,
                fixed: 500,
                monthly: 500
            });

            // Seed the default currency
            await firestore.add({
                collection: 'currencies'
            }, {
                abbreviation: 'USD',
                conversion: 1,
                ownerId: createResult.user.uid
            });

            this.setState({
                error: '',
                isDone: true
            });
        }
        catch(e) {
            this.setState({
                error: e && e.message ? e.message : 'Unknown error.'
            });
        };
    }

    onValueChanged = (e) => {
        let update = {};
        update[e.target.id] = e.target.value || '';

        this.setState(update);
    }

    render() {
        if (this.state.isDone) {
            return <Redirect to='/settings' />
        }
        if (this.props.authExists && !this.state.isBusy) {
            return <Redirect to='/' />
        }

        return (
            <div>
                <Helmet>
                    <title>Budget - Register</title>
                </Helmet>

                <article className='row mb-4 justify-content-center'>
                    <div className='col-12 col-md-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h2>Register</h2>
                            </div>

                            <div className='card-body mt-4'>
                                <form onSubmit={this.register}>
                                    <div className='form-group form-row'>
                                        <label htmlFor='friendlyName' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Name</label>
                                        <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='friendlyName' value={this.state.friendlyName || ''} onChange={this.onValueChanged} autoFocus required disabled={this.state.isBusy} />
                                        <small className='form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2'>Whatever you'd like us to call you. Be nice to yourself.</small>
                                    </div>
                                    <div className='form-group form-row'>
                                        <label htmlFor='email' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Email</label>
                                        <input type='email' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='email' value={this.state.email || ''} onChange={this.onValueChanged} required disabled={this.state.isBusy}/>
                                    </div>
                                    <div className='form-group form-row'>
                                        <label htmlFor='password' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Password</label>
                                        <input type='password' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='password' value={this.state.password || ''} onChange={this.onValueChanged} required disabled={this.state.isBusy} />
                                    </div>

                                    {
                                        this.state.error ? (
                                            <div className='form-group row'>
                                                <span className='col-sm-12 text-danger'>{this.state.error}</span>
                                            </div>
                                        ) : null
                                    }

                                    <hr />

                                    { !this.state.isBusy ? (
                                        <div>
                                            <button type='submit' className='btn btn-primary' onClick={this.register}>Register</button> or <Link to='/login'>login</Link>
                                        </div>
                                    ) : <Loading /> }
                                </form>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default withFirebase(withFirestore(Register));