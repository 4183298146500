import React from 'react';

import Spinner from './Spinner';

export default ({ save, cancel, id, isBusy }) => (
    <div className='position-relative'>
        <div style={isBusy ? { visibility: 'hidden' } : null}>
            <button type='submit' className='btn btn-primary' onClick={save}>{id ? 'Save' : 'Add'}</button>
            <a href='javascript:void(0)' className='ml-3' onClick={cancel}>{id ? 'Cancel' : 'Clear'}</a>
        </div>
        {isBusy ? (
            <div className='loading-cover loading-cover-cell'>
                <Spinner />
            </div>
        ) : null}
    </div>
);