import { setUser, setAuthLoaded } from './actions';

import store from 'modules/app/store';
import firebase from '../../data/firebase';

export default function initAuth() {
    // TODO: remove after react-redux-firebase auth is fixed
    firebase.auth().onAuthStateChanged(authData => {
        if (!authData) {
            store.dispatch(setUser(null));
        }
        else {
            store.dispatch(setUser({
                uid: authData.uid,
                email: authData.email,
                displayName: authData.displayName
            }));
        }

        store.dispatch(setAuthLoaded(true));
    });
}