import React from 'react';
import { isLoaded } from 'react-redux-firebase';

import Loading from 'common/components/Loading';
import CurrencyListItem from '../containers/CurrencyListItem';

export default ({ currencies }) => {
    if (!isLoaded(currencies)) {
        return <Loading />;
    }

    return (
        <table className='table table-striped table-responsive-xs'>
            <thead>
                <tr>
                    <th scope='col'>Abbreviation</th>
                    <th scope='col'>Conversion</th>
                    <th scope='col'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {currencies != null ? Object.keys(currencies).map((k) => <CurrencyListItem currency={currencies[k]} key={k} id={k} />) : null}
            </tbody>
        </table>
    );
};