import React, { Component } from 'react';

import Page from 'common/components/Page';

import ForecastList from '../containers/ForecastList';

class ForecastLayout extends Component {
    render() {
        return (
            <Page title='Forecast'>
                <ForecastList />
            </Page>
        );
    }
}

export default ForecastLayout;