import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { default as Component } from '../components/RequireAuth';

export default compose(
    withRouter,
    connect(
        ({ auth }) => ({ auth })
    )
)(Component);