import React, { Component } from 'react';

import Page from 'common/components/Page';

import EditProfile from '../containers/EditProfile';

class TransactionLayout extends Component {
    render() {
        return (
            <Page title='Profile' editor={EditProfile} disableFilter={true} />
        );
    }
}

export default TransactionLayout;