import moment from 'moment';

import * as t from './actionTypes';

const initialState = {
    selectedTransaction: {
        date: '',
        description: '',
        currency: 'USD',
        amount: '',
        category: '',
        note: ''
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.NEW_TRANSACTION:
            return {
                ...state,
                selectedTransaction: {
                    ...initialState.selectedTransaction,
                    ...action.payload
                }
            };
        case t.EDIT_TRANSACTION:
            return {
                ...state,
                selectedTransaction: {
                    ...state.selectedTransaction,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};