import * as t from './actionTypes';

const initialState = {
    isLoaded: false,
    user: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case t.SET_AUTHLOADED:
            return {
                ...state,
                isLoaded: action.payload
            };
        default:
            return state;
    }
};