import React, { Component } from 'react';
import { isLoaded, withFirestore } from 'react-redux-firebase';

import FormActions from 'common/components/FormActions';
import Loading from 'common/components/Loading';

class EditCurrency extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    save = async (e) => {
        let s = this.props.currency;
        let { user } = this.props.auth;
        let { currencies } = this.props;

        e.preventDefault();

        if (!s.abbreviation || !s.conversion) {
            return false;
        }
        if (!s.id &&
            currencies && currencies.length &&
            currencies.find(c => c.abbreviation.toUpperCase() === s.abbreviation.toUpperCase())) {
            return false;
        }

        this.setState({
            isBusy: true
        });

        let conversion = (+s.conversion);
        conversion = isNaN(conversion) ? 0 : conversion;

        let currency = {
            ...s,
            ownerId: user.uid
        };

        let id = undefined;

        if (currency.id) {
            id = currency.id;
            delete currency.id;
        }

        if (id) {
            await this.props.firestore.set({ collection: 'currencies', doc: id }, currency);
        }
        else {
            await this.props.firestore.add({ collection: 'currencies' }, currency);
        }

        this.props.createCurrency();

        this.setState({
            isBusy: false
        });

        this.abbreviation.focus();
    }

    cancel = (e) => {
        e.preventDefault();

        this.props.createCurrency();
    }

    onValueChanged = (e) => {
        let update = {
            ...this.props.currency
        };

        let prop = e.target.dataset.property;

        update[prop] = e.target.value;

        this.props.editCurrency(update);
    }

    render() {
        const { currency } = this.props;

        if (!isLoaded(currency)) {
            return <Loading />;
        }

        return (
            <form onSubmit={this.save}>
                <div className='form-group form-row'>
                    <label htmlFor='abbreviation' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Abbreviation</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='abbreviation' data-property='abbreviation' value={currency.abbreviation} onChange={this.onValueChanged} ref={(i) => this.abbreviation = i} required />
                </div>

                <div className='form-group form-row'>
                    <label htmlFor='conversion' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Conversion</label>
                    <input type='number' min={0} step={0.1} className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='conversion' data-property='conversion' value={currency.conversion} onChange={this.onValueChanged} required />
                    <small id='conversionHelp' className='form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2'>Updating a currency affects all future updates to any transaction type.</small>
                </div>

                <hr />

                <FormActions save={this.save} cancel={this.cancel} id={currency.id} isBusy={this.state.isBusy} />
            </form>
        );
    }
}

export default withFirestore(EditCurrency);