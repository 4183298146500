import React, { Component } from 'react';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase';

import GridActions from 'common/components/GridActions';

import { formatCurrency } from 'utilities/formatters';

class BigExpenseListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    edit = () => {
        this.props.editBigExpense({
            ...this.props.bigExpense
        });
    }

    remove = async () => {
        if (!confirm('Delete this item?')) {
            return;
        }

        this.setState({
            isBusy: true
        });

        await this.props.firestore.delete({ collection: 'bigExpenses', doc: this.props.bigExpense.id });
    }

    render() {
        let { bigExpense } = this.props;

        if (!bigExpense || !bigExpense.startDate) {
            return null;
        }

        let mstart = moment(bigExpense.startDate.toDate());
        let mend = moment(bigExpense.endDate.toDate());

        return (
            <tr>
                <th scope='row' title={mstart.format('dddd')}>{mstart.format('MM/DD/YYYY')}</th>
                <th scope='row' title={mend.format('dddd')}>{mend.format('MM/DD/YYYY')}</th>
                <td>{bigExpense.duration}</td>
                <td>{bigExpense.description}</td>
                <td>{formatCurrency(bigExpense.amount)}</td>
                <td>{bigExpense.currency}</td>
                <td>{formatCurrency(bigExpense.amountBase)}</td>
                <td>{formatCurrency(bigExpense.dailyAmount)}</td>
                <GridActions isBusy={this.state.isBusy} onEdit={this.edit} onDelete={this.remove} />
            </tr>
        );
    }
}

export default withFirestore(BigExpenseListItem);