import React, { Component } from 'react';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase';

import GridActions from 'common/components/GridActions';

import { formatCurrency } from 'utilities/formatters';

class TransactionListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    edit = () => {
        this.props.editTransaction({
            id: this.props.id,
            ...this.props.transaction
        });
    }

    remove = async () => {
        let { transaction } = this.props;

        if (!confirm('Delete this item?')) {
            return;
        }

        this.setState({
            isBusy: true
        });

        let { auth: { user } } = this.props;

        await this.calculateAggregates(transaction, user.uid);

        await this.props.firestore.delete({ collection: 'transactions', doc: transaction.id });
    }

    calculateAggregates = async (transaction, uid) => {
        let aggregateMonth = moment(transaction.date.toDate()).startOf('month').toDate();
        // Load only on demand for delete, don't load in container as deletes aren't as common
        //  and we don't want to run the logic for each and every transaction row in the list
        let aggregateSnapshot = await this.props.firestore.get({
            collection: 'monthlyAggregates',
            where: [
                ['ownerId', '==', uid],
                ['month', '==', aggregateMonth]
            ],
            limit: 1,
            storeAs: `monthlyAggregates/${aggregateMonth.getTime()}`
        });

        let aggregates = !aggregateSnapshot.empty ? aggregateSnapshot.docs[0].data() : {};

        let amount = (+transaction.amountBase) || 0;
        let total = (aggregates.total || 0) - amount;
        let categoryTotal = (aggregates[transaction.category] || 0) - amount;

        let update = {
            ...aggregates,
            total: Math.max(total, 0),
            [transaction.category]: Math.max(categoryTotal, 0),
            ownerId: uid
        };

        if (!aggregateSnapshot.empty) {
            let id = aggregateSnapshot.docs[0].id;
            return await this.props.firestore.set({ collection: 'monthlyAggregates', doc: id }, update);
        }

        return await this.props.firestore.add({ collection: 'monthlyAggregates' }, update);
    }

    render() {
        let { transaction } = this.props;

        let mdate = moment(transaction.date.toDate());

        return (
            <tr>
                <th scope='row' title={mdate.format('dddd')}>{mdate.format('MMM DD')}</th>
                <td>{transaction.description}</td>
                <td>{formatCurrency(transaction.amount)}</td>
                <td>{transaction.currency}</td>
                <td>{formatCurrency(transaction.amountBase)}</td>
                <td>{transaction.category}</td>
                <td>{transaction.note}</td>
                <GridActions isBusy={this.state.isBusy} onEdit={this.edit} onDelete={this.remove} />
            </tr>
        );
    }
}

export default withFirestore(TransactionListItem);