import React, { Component } from 'react';
import moment from 'moment';
import { isLoaded } from 'react-redux-firebase';

import { Categories } from 'data/categories';

import Currency from 'common/containers/Currency';
import FormActions from 'common/components/FormActions';
import Loading from 'common/components/Loading';

import { getDateOrDefault, getDateForUpsert } from 'utilities/date';
import { formatCurrency } from 'utilities/formatters';

class EditTransaction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false
        };
    }

    save = async (e) => {
        let trx = this.props.transaction;
        let { user } = this.props.auth;

        e.preventDefault();

        if (!trx.amount == null || !trx.description || !trx.category) {
            return false;
        }

        this.setState({
            isBusy: true
        });

        let amount = (+trx.amount);
        amount = isNaN(amount) ? 0 : amount;

        let transaction = {
            ...trx,
            date: getDateForUpsert(trx.date, this.props.today),
            amount: +amount,
            amountBase: +(trx.amountBase || trx.amount),
            ownerId: user.uid
        };

        let id = undefined;

        if (trx.id) {
            id = trx.id;
            // Remove the superfluous id prop
            delete transaction.id;
        }

        if (id) {
            let currentSnapshot = await this.props.firestore.get({ collection: 'transactions', doc: id });
            let currentValue = currentSnapshot.exists ? currentSnapshot.data() : {};

            await this.props.firestore.set({ collection: 'transactions', doc: id }, transaction);
            await this.adjustAggregates(currentValue, user.uid, -1);
        }
        else {
            await this.props.firestore.add({ collection: 'transactions' }, transaction);
        }

        await this.adjustAggregates(transaction, user.uid);

        // Toggle to create form
        this.props.createTransaction({
            category: trx.category,
            date: trx.date
        });

        this.setState({
            isBusy: false
        });

        this.description.focus();
    }

    cancel = (e) => {
        e.preventDefault();

        this.props.createTransaction();
    }

    adjustAggregates = async (transaction, uid, factor = 1) => {
        if (!transaction || !transaction.date) {
            return;
        }

        const aggregates = this.props.aggregates || {};

        let date = transaction.date && transaction.toDate ?
            moment(transaction.toDate()) : moment(transaction.date);
        let dateKey = date.startOf('month').toDate();

        let amount = ((+transaction.amountBase) || 0) * factor;
        let total = (aggregates.total || 0) + amount;
        let categoryTotal = (aggregates[transaction.category] || 0) + amount;

        let update = {
            ...aggregates,
            month: dateKey,
            total: total,
            [transaction.category]: categoryTotal,
            ownerId: uid
        };

        if (update.id) {
            let id = update.id;
            delete update.id;
            return await this.props.firestore.set({ collection: 'monthlyAggregates', doc: id }, update);
        }

        return await this.props.firestore.add({ collection: 'monthlyAggregates' }, update);
    }

    onValueChanged = (e) => {
        let update = {
            ...this.props.transaction
        };

        let prop = e.target.dataset.property;

        update[prop] = e.target.value;

        this.props.editTransaction(update);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transaction.id != this.props.transaction.id && this.props.transaction.id != null) {
            this.description.scrollIntoViewIfNeeded();
        }
    }

    autofill = (e) => {
        let { recentTransaction } = this.props;

        let update = {
            amount: recentTransaction.amount,
            amountBase: recentTransaction.amountBase,
            currency: recentTransaction.currency,
            category: recentTransaction.category,
            note: recentTransaction.note
        };

        e.preventDefault();

        this.props.editTransaction(update);
    }

    render() {
        let {
            aggregates,
            transaction,
            recentTransaction,
            today
        } = this.props;

        if (!isLoaded(aggregates, transaction)) {
            return <Loading />;
        }

        let suggestion = null;
        // Load suggestions only for new records, where we've found one, and there is a description set, but we're not creating
        if (!this.state.isBusy && !transaction.id &&
            isLoaded(recentTransaction)) {
            suggestion = recentTransaction;
        }

        let date = getDateOrDefault(transaction.date, today);

        return (
            <form onSubmit={this.saveTransaction}>
                <div className='form-group form-row'>
                    <label htmlFor='date' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Date</label>
                    <input type='date' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='date' data-property='date' value={date} onChange={this.onValueChanged} required disabled={this.state.isBusy} />
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='description' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Description</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='description' data-property='description' value={transaction.description || ''} onChange={this.onValueChanged} ref={(i) => this.description = i} required disabled={this.state.isBusy} />
                    {suggestion ? (
                        <small id='conversionHelp' className='form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2'>
                            We found a similar transaction from {moment(suggestion.date.toDate()).format('MM/DD/YYYY')} for {formatCurrency(suggestion.amount)} {suggestion.currency} filed under {suggestion.category}. <a href="javascript:void(0)" onClick={this.autofill}>Autofill?</a>
                        </small>
                    ) : null}
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='amount' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Amount</label>
                    <div className='col-7 col-sm-8 col-md-9 col-lg-10 p-0'>
                        <Currency id='amount' value={transaction.amount} currency={transaction.currency || ''} onChange={this.props.editTransaction} disabled={this.state.isBusy} />
                    </div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='amount' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Category</label>
                    <select className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='category' data-property='category' value={transaction.category || ''} onChange={this.onValueChanged} required disabled={this.state.isBusy}>
                        <option></option>
                        {Categories.map((c, i) => <option key={i} value={c}>{c}</option>)}
                    </select>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='note' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Note</label>
                    <input type='text' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='note' data-property='note' value={transaction.note || ''} onChange={this.onValueChanged} disabled={this.state.isBusy} />
                </div>

                <hr />

                <FormActions save={this.save} cancel={this.cancel} id={transaction.id} isBusy={this.state.isBusy} />
            </form>
        );
    }
}

export default EditTransaction;