import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loading from 'common/components/Loading';

export default function withAuth(WrappedComponent) {
    return connect(
        ({ auth }) => ({ auth })
    )(class AuthConnect extends Component {
        render() {
            const { auth } = this.props;

            if (!auth || !auth.user || !auth.user.uid) {
                return <Loading />;
            }

            return <WrappedComponent {...this.props} {...this.state} />;
        }
    });
}