import React from 'react';
import { isLoaded } from 'react-redux-firebase';

import Loading from 'common/components/Loading';
import BigExpenseListItem from '../containers/BigExpenseListItem';

export default ({ bigExpenses }) => {
    if (!isLoaded(bigExpenses)) {
        return <Loading />;
    }

    return (
        <table className='table table-striped table-responsive-sm'>
            <thead>
                <tr>
                    <th scope='col'>Start Date</th>
                    <th scope='col'>End Date</th>
                    <th scope='col'>Duration</th>
                    <th scope='col'>Description</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Currency</th>
                    <th scope='col'>Amount Base</th>
                    <th scope='col'>Daily Amount</th>
                    <th scope='col'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {bigExpenses.map((b) => <BigExpenseListItem bigExpense={b} key={b.id} />)}
            </tbody>
        </table>
    );
};