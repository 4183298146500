import React from 'react';
import { v4 } from 'uuid';

export default ({ value, label, hoverText, reverseGradient }) => {
    value = value || 0;

    label = label || `${value}%`;

    let fill = null;

    if (reverseGradient) {
        fill = value < 40 ? '#28a745' :
            value < 60 ? '#ffc107' :
                '#dc3545';
    }
    else {
        fill = value < 10 ? '#dc3545' :
            value < 40 ? '#ffc107' :
                '#28a745';
    }

    let id = v4().replace(/-/g, '');
    let rectId = `rect${id}`;
    let clipId = `clip${id}`;

    return (
        <svg width='100%' height='25px'>
            <g className='bars'>
                <title>{hoverText}</title>
                <rect fill='#ccc' width='100%' height='25px' />
                <text x='50%' y='55%' fill={fill} dominantBaseline='middle' textAnchor='middle' fontWeight='bold' style={{ textShadow: '0px 1px 1px #444' }}>{label}</text>
                <rect fill={fill} width={`${value}%`} height='25px' id={rectId} />
                <clipPath id={clipId}>
                    <use xlinkHref={`#${rectId}`} />
                </clipPath>
                <text x='50%' y='55%' fill='#fff' clipPath={`url('#${clipId}')`} dominantBaseline='middle' textAnchor='middle' fontWeight='bold' style={{ textShadow: '0px 1px 1px #444' }}>{label}</text>
            </g>
        </svg>
    );
};