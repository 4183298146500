import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setUser } from 'modules/auth/actions';

import { default as Component } from '../components/MainLayout';

function mapStateToProps({ auth }) {
    return {
        auth
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUser
    }, dispatch);
}

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default Container;