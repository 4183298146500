import * as t from './actionTypes';

export function createReimbursable(defaults) {
    return {
        type: t.NEW_REIMBURSABLE,
        payload: defaults || {}
    };
}

export function editReimbursable(transaction) {
    return {
        type: t.EDIT_REIMBURSABLE,
        payload: transaction
    };
}