import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Reset extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            isInitiated: false
        };
    }

    reset = (e) => {
        e.preventDefault();

        if (!this.state.email) {
            this.setState({
                error: 'Email is required to reset your credentials'
            });
            return;
        }

        this.props.firebase
            .auth()
            .sendPasswordResetEmail(this.state.email)
            .then(response => {
                this.setState({
                    error: null,
                    isInitiated: true
                });
            }, (error) => {
                this.setState({
                    error: error && error.message ? error.message : 'Unknown failure'
                });
            });
    }

    onValueChanged = (e) => {
        let update = {};
        update[e.target.id] = e.target.value || '';

        this.setState(update);
    }

    render() {
        if (this.props.authExists) {
            let location = window.location.search.split('=')[1] || '/';
            return <Redirect to={location} />
        }
        if (this.state.isInitiated) {
            return <Redirect to='/login' />
        }

        return (
            <div>
                <Helmet>
                    <title>Budget - Reset Password</title>
                </Helmet>

                <article className='row mb-4 justify-content-sm-center'>
                    <div className='col-12 col-md-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h2>Reset Password</h2>
                            </div>

                            <div className='card-body mt-4'>
                                <form onSubmit={this.reset}>
                                    <div className='form-group form-row'>
                                        <label htmlFor='email' className='col-5 col-sm-4 col-md-3 col-lg-2 col-form-label'>Email</label>
                                        <input type='email' className='form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10' id='email' value={this.state.email || ''} onChange={this.onValueChanged} autoFocus required />
                                    </div>

                                    {
                                        this.state.error ? (
                                            <div className='form-group row'>
                                                <span className='col-12 text-danger'>{this.state.error}</span>
                                            </div>
                                        ) : null
                                    }

                                    <div>
                                        <button type='submit' className='btn btn-primary' onClick={this.reset}>Send Password Reset Link</button> or <Link to='/login'>login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default withFirebase(Reset);