import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { firebaseReducer as firebase } from 'react-redux-firebase';
import { firestoreReducer as firestore } from 'redux-firestore';

import { SET_USER } from 'modules/auth/actionTypes';

import { default as app } from './reducer';
import { reducer as auth } from 'modules/auth';
import { reducer as bigExpenses } from 'modules/bigExpenses';
import { reducer as currencies } from 'modules/currencies';
import { reducer as profile } from 'modules/profile';
import { reducer as reimbursables } from 'modules/reimbursables';
import { reducer as transactions } from 'modules/transactions';

const middlewares = [];
if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedReducers = combineReducers({
    app,
    auth,
    bigExpenses,
    currencies,
    firebase,
    firestore,
    profile,
    reimbursables,
    transactions
});

const rootReducer = (state, action) => {
    // Handle logout
    if (action.type === SET_USER && action.payload == null) {
        // // Redux persist cleanup
        // Object.keys(state).forEach(key => {
        //     storage.removeItem(`persist:${key}`);
        // });
        state = undefined;
    }

    return combinedReducers(state, action);
};

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
);

export default store;