import { connect } from 'react-redux';
import {  bindActionCreators } from 'redux';

import { setToday } from 'modules/app/actions';

import { default as Component } from '../components/DateFilter';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setToday
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        today: state.app.today
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);