import React from 'react';
import classNames from 'classnames';

export default ({ label, value, selectedValue, onChange }) => {
    const isChecked = selectedValue === value;
    const className = classNames('btn btn-secondary', {
        'active': isChecked
    });

    return (
        <label className={className} htmlFor={value} onClick={onChange}>
            <input type="radio" name="list-filter" id={value} value={value} checked={isChecked} onChange={onChange} /> {label}
        </label>
    );
};