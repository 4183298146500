import { connect } from 'react-redux';
import {  bindActionCreators, compose } from 'redux';

import { withAuth } from 'modules/auth';
import withToday from 'common/containers/withToday';

import { createBigExpense, editBigExpense } from '../actions';

import { default as Component } from '../components/EditBigExpense';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createBigExpense,
        editBigExpense
    }, dispatch);
}

const getSelectedBigExpense = (state) => {
    if (!state || !state.bigExpenses || !state.bigExpenses.selectedBigExpense) {
        return;
    }

    return state.bigExpenses.selectedBigExpense;
};

function mapStateToProps(state) {
    return {
        bigExpense: getSelectedBigExpense(state)
    };
}

const Container = compose(
    withAuth,
    withToday,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Component);

export default Container;