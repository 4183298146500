import moment from 'moment';

import * as t from './actionTypes';

const initialState = {
    selectedBigExpense: {
        startDate: '',
        endDate: '',
        description: '',
        amount: ''
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.NEW_BIGEXPENSE:
            return {
                ...state,
                selectedBigExpense: {
                    ...initialState.selectedBigExpense,
                    ...action.payload
                }
            };
        case t.EDIT_BIGEXPENSE:
            return {
                ...state,
                selectedBigExpense: {
                    ...state.selectedBigExpense,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};