import * as t from './actionTypes';

export function createBigExpense(defaults) {
    return {
        type: t.NEW_BIGEXPENSE,
        payload: defaults || {}
    };
}

export function editBigExpense(transaction) {
    return {
        type: t.EDIT_BIGEXPENSE,
        payload: transaction
    };
}