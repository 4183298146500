import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withAuth } from 'modules/auth';

import { editTransaction } from '../actions';

import { default as Component } from '../components/TransactionListItem';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        editTransaction
    }, dispatch);
}

const Container = compose(
    withAuth,
    connect(
        null,
        mapDispatchToProps
    )
)(Component);

export default Container;