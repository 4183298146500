import * as t from './actionTypes';

const initialState = {
    selectedCurrency: {
        abbreviation: '',
        conversion: ''
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.NEW_CURRENCY:
            return {
                ...state,
                selectedCurrency: {
                    ...initialState.selectedCurrency,
                    ...action.payload
                }
            };
        case t.EDIT_CURRENCY:
            return {
                ...state,
                selectedCurrency: {
                    ...state.selectedCurrency,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};