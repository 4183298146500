import React from 'react';
import { render } from 'react-dom';

import './stylesheets/main.scss';
import 'utilities/fontawesome';

import { App } from './modules/app';

// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React);
// }

render(
    <App />,
    document.getElementById('app')
);