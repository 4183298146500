import { compose } from 'redux';
import { connect } from 'react-redux';

import { getDays, getMonthProjection, getPointColors } from 'data/selectors';
import withToday from 'common/containers/withToday';

import { default as Component } from '../components/BalanceForecast';

export default compose(
    withToday,
    connect((state) => {
        return {
            days: getDays(state),
            monthProjection: getMonthProjection(state),
            pointColors: getPointColors(state)
        };
    })
)(Component);